const GDPRPage = () => {
    return (<div className="relative py-16 overflow-hidden grow w-full bg-white">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-4xl mx-auto">
            <h1 className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
              Politică de Protecție a Datelor Personale
            </h1>
          </div>
          <div className="mt-6 max-w-4xl prose-lg text-gray-500 mx-auto">
            <h3 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              1. Introducere
            </h3>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              https://digigov.ro, operat de USETECH SRL, respectă drepturile și
              protecția datelor cu caracter personal ale utilizatorilor săi conform
              Regulamentului General privind Protecția Datelor (GDPR) și legislației
              aplicabile în domeniul protecției datelor.
            </p>
            <h3 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              2. Datele colectate
            </h3>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              https://digigov.ro colectează și prelucrează datele personale ale
              utilizatorilor săi în scopul furnizării serviciilor specifice oferite de
              site-ul nostru. Aceste date pot include, dar nu se limitează la: nume,
              adresă de email, număr de telefon, adresa poștală, informații de
              facturare și alte informații necesare pentru procesarea comenzilor și
              serviciilor.
            </p>
            <h3 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              3. Scopul colectării datelor
            </h3>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Datele personale colectate sunt utilizate în principal pentru
              următoarele scopuri:
            </p>
            <ul role="list" className="marker:text-gray-300 list-disc list-inside">
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Procesarea comenzilor și furnizarea serviciilor solicitate de
                utilizatori.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Comunicarea cu utilizatorii pentru a oferi asistență și răspunsuri la
                întrebări.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Îmbunătățirea serviciilor și personalizarea experienței
                utilizatorilor.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Respectarea obligațiilor legale și fiscale în conformitate cu
                legislația în vigoare.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Păstrarea datelor
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Datele personale colectate de https://digigov.ro vor fi păstrate doar
                pe perioada necesară pentru atingerea scopurilor menționate mai sus
                sau în conformitate cu cerințele legale aplicabile.
              </li>
            </ul>
            <h3 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              4. Securitatea datelor
            </h3>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Ne angajăm să protejăm datele personale ale utilizatorilor împotriva
              accesului neautorizat, divulgării sau distrugerii prin implementarea
              măsurilor de securitate adecvate.
            </p>
            <h3 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              5. Divulgarea datelor către terți
            </h3>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Datele personale colectate de https://digigov.ro nu vor fi transferate
              către terți, cu excepția situațiilor în care acest lucru este necesar
              pentru a furniza serviciile solicitate de utilizatori. Orice transfer de
              date către terți va fi efectuat numai cu acordul explicit al
              utilizatorilor și în scopul de a duce solicitarea acestora mai departe,
              în conformitate cu cerințele legale și contractuale aplicabile.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Echipa https://digigov.ro va asigura că terții implicați în prelucrarea
              datelor personale respectă standardele de confidențialitate și
              securitate corespunzătoare pentru a proteja datele utilizatorilor. Vom
              lua măsuri adecvate pentru a ne asigura că aceste transferuri de date
              sunt realizate în deplină conformitate cu GDPR și cu respectarea
              drepturilor utilizatorilor.
            </p>
            <h3 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              6. Drepturile utilizatorilor
            </h3>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              În conformitate cu GDPR, utilizatorii au dreptul de a solicita
              informații privind datele personale stocate de https://digigov.ro, de a
              rectifica datele incorecte, de a solicita ștergerea datelor personale
              sau restricționarea prelucrării, precum și de a se opune prelucrării
              datelor personale în anumite condiții. Pentru exercitarea acestor
              drepturi, utilizatorii pot contacta https://digigov.ro prin intermediul
              adresei de email: asistenta@digigov.ro.
            </p>
            <h3 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              7. Modificări ale Politicii de Confidențialitate
            </h3>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Această Politică de Confidențialitate poate fi actualizată periodic
              pentru a reflecta schimbările aduse serviciilor noastre sau modificările
              legislative. Orice modificare semnificativă a Politicii de
              Confidențialitate va fi adusă la cunoștința utilizatorilor în mod
              transparent și în timp util. Prin continuarea utilizării serviciilor
              noastre după aceste modificări, vă exprimați acordul cu noile prevederi
              ale Politicii de Confidențialitate.
            </p>
            <h3 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              8. Contact
            </h3>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Dacă aveți întrebări, nelămuriri sau doriti să exercitați drepturile
              dumneavoastră cu privire la datele personale, vă rugăm să ne contactați
              prin intermediul adresei de email:asistenta@digigov.ro. Echipa noastră
              vă stă la dispoziție pentru a vă oferi informațiile și asistența
              necesare.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Data ultimei actualizări: 3/08/2023
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Vă încurajăm să citiți cu atenție această{" "}
              <a
                className="text-indigo-800 hover:text-indigo-950"
                href="/politica-de-confidentialitate"
              >
                Politică de Confidențialitate
              </a>{" "}
              și să ne contactați dacă aveți întrebări sau nelămuriri.
            </p>
          </div>
        </div>
      </div>
      );
}

export default GDPRPage;