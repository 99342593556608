import React from 'react';

const BillingReview = ({ billingData }) => {
    if (!billingData) return <></>;
  const isLegalEntity = billingData.facturare_firma.length > 0;
  return (
    <div className="flex flex-col rounded-xl p-4 border shadow justify-between border-indigo-300 bg-indigo-100">
      <h2 className="font-semibold text-2xl mb-2 border-b-2 border-indigo-300">Revizuire Facturare</h2>
      <div className="w-full grid md:grid-cols-2 gap-4">
        <div>
          <p className="text-base leading-5 font-bold text-gray-900">Nume:</p>
          <p className="text-sm font-medium text-gray-500">
            {isLegalEntity ? billingData.facturare_firma : `${billingData.facturare_firstName} ${billingData.facturare_lastName}`}
          </p>
        </div>
        <div>
          <p className="text-base leading-5 font-bold text-gray-900">Adresă:</p>
          <p className="text-sm font-medium text-gray-500">
            {isLegalEntity ? billingData.facturare_adresa_firma : billingData.facturare_adresa}
          </p>
        </div>
        <div>
          <p className="text-base leading-5 font-bold text-gray-900">Oraș/Județ:</p>
          <p className="text-sm font-medium text-gray-500">
            {isLegalEntity ? `${billingData.facturare_oras_firma} / ${billingData.facturare_judet_firma}` : `${billingData.facturare_oras} / ${billingData.facturare_judet}`}
          </p>
        </div>
        <div>
          <p className="text-base leading-5 font-bold text-gray-900">Telefon:</p>
          <p className="text-sm font-medium text-gray-500">
            {isLegalEntity ? billingData.facturare_phone_firma : billingData.facturare_phone}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BillingReview;
