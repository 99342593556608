const ContactPage = () => {

    return (<div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24 w-full grow">
        <div className="relative max-w-xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
              Contactaţi-ne
            </h2>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              Nu ezitați să ne contactați dacă aveți întrebări sau sugestii pentru a
              ne îmbunătăți serviciile.
            </p>
          </div>
          <div className="mt-12">
            <form
              method="POST"
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prenume
                </label>
                <div className="mt-1">
                  <input
                    id="first-name"
                    autoComplete="given-name"
                    required=""
                    className="py-3 px-4 block w-full shadow-sm border focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                    type="text"
                    defaultValue=""
                    name="first-name"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nume
                </label>
                <div className="mt-1">
                  <input
                    id="last-name"
                    autoComplete="family-name"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded-md"
                    type="text"
                    defaultValue=""
                    name="last-name"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  E-mail
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    autoComplete="email"
                    required=""
                    className="py-3 px-4 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded-md"
                    type="email"
                    defaultValue=""
                    name="email"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mesaj
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    required=""
                    className="py-3 px-4 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded-md"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-fuchsia-600 hover:bg-fuchsia-700 focus:ring-fuchsia-800 focus:border-fuchsia-600 text-white disabled:bg-gray-400"
                >
                  Hai să vorbim
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      );
};

export default ContactPage;