import React, { useEffect, useState } from 'react';
import Revizuire from './Revizuire';
import { API_URL } from "../config";
import BillingReview from './BillingReview';
import DeliveryReview from './DeliveryReview';

const prices = {
  "traducere legalizata": 0,
  "apostila haga": 158,
  "tara_haga": 0,
  "urgent": 84,
  "electronic": 0,
  "tara_utilizare": 252,
  "limba_traducere": 0,
  "expert": 49,
  'livrare': 0,
  'time': 0,
};

const computeProduct = (product) => {
  let price = 210;
  let comanda = {};
  Object.keys(product).forEach(key => {
    if (key in prices && product[key]) {
      price += prices[key];
      comanda[key] = product[key];
    }
    if (key === 'livrare' && product[key]['delivery_country'] !== 'Romania') {
      price += 250;
    }
  });
  return { comanda, price };
};

const Modal = ({ client, idProcesareClient, isOpen, onClose, onUpdateStatus, password }) => {
  const [ownerImageURL, setOwnerImageURL] = useState(null);
  const [documentImageURL, setDocumentImageURL] = useState(null);
  const [contractFileURL, setContractFileURL] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchImageURL('owner', setOwnerImageURL);
      fetchImageURL('document', setDocumentImageURL);
      fetchImageURL('contract', setContractFileURL);
    }
  }, [isOpen]);

  const fetchImageURL = async (imageName, setImageURL) => {
    try {
      const response = await fetch(`${API_URL}/api/getImage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'X-Password': password },
        body: JSON.stringify({ idProcesareClient, imageName }),
      });
      const data = await response.json();
      if (data.downloadURL) {
        setImageURL(data.downloadURL);
      } else {
        throw new Error('Failed to get download URL');
      }
    } catch (error) {
      console.error(`Failed to fetch ${imageName} URL`, error);
    }
  };

  if (!isOpen) return null;

  const { comanda, price } = computeProduct(client);

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-5xl sm:w-full" style={{ maxHeight: "86vh", marginTop: "3rem" }}>
        <div className="px-4 py-5 sm:p-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 max-w-5xl min-h-[500px]">
            <div className="mt-2">
              <Revizuire formData={{ invoice: "", client, product: comanda, idProcesareClient }} total={price} />
              <p><strong>Order Status:</strong> {client.orderStatus}</p>
            </div>
            <div className="mt-1">
              {ownerImageURL && (
                <div>
                  <strong>Owner Image:</strong>
                  <img src={ownerImageURL} alt="Owner" style={{ maxWidth: '320px', maxHeight: '210px' }} className="mt-2" />
                  <button
                    onClick={() => window.open(ownerImageURL, '_blank')}
                    className="mt-2 px-4 py-2 bg-violet-500 text-white rounded"
                  >
                    Download Owner Image
                  </button>
                </div>
              )}
              {documentImageURL && (
                <div className="mt-1">
                  <strong>Document Image:</strong>
                  <img src={documentImageURL} alt="Document" style={{ maxWidth: '320px', maxHeight: '210px' }} className="mt-2" />
                  <button
                    onClick={() => window.open(documentImageURL, '_blank')}
                    className="mt-2 px-4 py-2 bg-violet-500 text-white rounded"
                  >
                    Download Document Image
                  </button>
                </div>
              )}
              {contractFileURL && (
                <div className="mt-1 p-2">
                  <strong>Contract: </strong>
                  <button
                    onClick={() => window.open(contractFileURL, '_blank')}
                    className="mt-2 px-4 py-2 bg-violet-500 text-white rounded"
                  >
                    Download Contract
                  </button>
                </div>
              )}
            </div>
            <div className="mt-1">
            <BillingReview billingData={client.billing}/>
            <DeliveryReview deliveryData={client.livrare}/>
                </div>
          </div>
          <div className="px-4 pb-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onUpdateStatus(idProcesareClient, 'Preluat')}
          >
            Preluat
          </button>
          <button
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onUpdateStatus(idProcesareClient, 'Processing')}
          >
            Processing
          </button>
          <button
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-500 text-base font-medium text-white hover:bg-yellow-700 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onUpdateStatus(idProcesareClient, 'Shipped')}
          >
            Shipped
          </button>
          <button
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-500 text-base font-medium text-white hover:bg-purple-700 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onUpdateStatus(idProcesareClient, 'Delivered')}
          >
            Delivered
          </button>
          <button
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}
          >
            Cancel
          </button>
          
        </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
