import React from 'react';
import { useNavigate } from 'react-router-dom';
import adelina from "./../assets/Adelina.jpg";
import andrei from "./../assets/Andrei.jpg";
import iulia from "./../assets/Iulia.jpg";

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

const LandingPage = ({setIdProcesareClient}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        // Navigate to a new route and append the ID to the path
        const id = makeid(10);
        setIdProcesareClient(id);
        navigate(`/cazier/${id}`);
    };

  return (
    <div className='w-full grow'>
        <div
  className="overflow-hidden bg-indigo-800 pattern">
  <div className="py-14 sm:py-20 sm:px-2 lg:relative lg:py-24 lg:px-0">
    <div className="mx-auto max-w-2xl flex flex-col gap-y-8 md:gap-y-16 gap-x-8 px-4 lg:max-w-7xl lg:flex-row lg:px-8 lg:items-center xl:gap-x-24 xl:px-16">
      <div className="relative z-10 md:text-center lg:text-left">
        <div className="relative">
          <h1 className="text-4xl sm:text-5xl inline font-display tracking-tight font-bold text-white">
            Obține documente online, fără cozi interminabile, în doar câteva
            click-uri
          </h1>
          <p className="text-lg sm:text-2xl mt-3 tracking-tight text-gray-200 font-semibold">
            Obține rapid documente de care ai nevoie printr-un proces 100%
            online, direct din confortul casei tale.
          </p>
        </div>
      </div>
      <div className="relative lg:static xl:pl-10 w-full lg:max-w-lg">
        <div className="relative">
          <div className="relative rounded-2xl bg-white">
            <div className="p-4 sm:p-10">
              <div className="flex items-start text-sm">
                <div className="w-full">
                  <div className="mt-1 sm:mt-0 sm:col-span-2 w-full">
                    <div className="flex justify-center border-2 border-transparent border-dashed rounded-md">
                      <div className="space-y-3 text-center flex flex-col">
                        <div className="text-xl sm:text-2xl text-slate-800 font-semibold">
                          Documente disponibile:
                        </div>
                        <div className="tracking-tight text-slate-700 pb-4">
                          <p className="mt-1 flex items-center text-sm sm:text-base font-medium">
                            <svg
                              className="flex-shrink-0 mr-2 h-5 w-5 text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Extras de Carte Funciară
                          </p>
                          <p className="mt-1 flex items-center text-sm sm:text-base font-medium">
                            <svg
                              className="flex-shrink-0 mr-2 h-5 w-5 text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Extras de Plan Cadastral
                          </p>
                          <p className="mt-1 flex items-center text-sm sm:text-base font-medium">
                            <svg
                              className="flex-shrink-0 mr-2 h-5 w-5 text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Certificat Constatator
                          </p>
                          <p className="mt-1 flex items-center text-sm sm:text-base font-medium">
                            <svg
                              className="flex-shrink-0 mr-2 h-5 w-5 text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Cazier Judiciar
                          </p>
                        </div>
                        <button
                          className="whitespace-nowrap inline-flex items-center justify-center px-4 py-3 border border-transparent rounded-md shadow-sm text-base font-bold text-white uppercase w-full bg-fuchsia-600 hover:bg-fuchsia-700 focus:ring-fuchsia-800 focus:border-fuchsia-600 text-white"
                          onClick={handleClick}
                        >
                          {" "}
                          Obține Document{" "}
                        </button>
                        <div className="flex items-center gap-1 text-gray-900  self-center">
                          <svg
                            className="w-4 h-4 text-yellow-300 me-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 22 20"
                          >
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                          </svg>
                          <p className="ms-2 text-sm font-bold">4.95</p>
                          <span className="text-sm">bazat pe</span>
                          <span className="text-sm font-medium">
                            153 recenzii
                          </span>
                          <div className="flex">
                            <div className="relative w-6 h-6 sm:w-8 sm:h-8 rounded-full overflow-hidden border-2 border-white -ml-3">
                            <img alt="Ana Maria" loading="lazy" decoding="async" data-nimg="fill" style={{position:"absolute",height:"100%",width:"100%",left:0,top:0,right:0,bottom:0,color:"transparent"}} sizes="100vw" src={iulia}/></div><div className="relative w-6 h-6 sm:w-8 sm:h-8 rounded-full overflow-hidden border-2 border-white -ml-3">
        <img alt="George Ionescu" loading="lazy" decoding="async" data-nimg="fill" style={{position:"absolute",height:"100%",width:"100%",left:0,top:0,right:0,bottom:0,color:"transparent"}} sizes="100vw" src={andrei}/></div><div className="relative w-6 h-6 sm:w-8 sm:h-8 rounded-full overflow-hidden border-2 border-white -ml-3">
          <img alt="Irina Popescu" loading="lazy" decoding="async" data-nimg="fill" style={{position:"absolute",height:"100%",width:"100%",left:0,top:0,right:0,bottom:0,color:"transparent"}} sizes="100vw" src={adelina}/></div><div className="relative w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-gray-900 text-white flex items-center border-2 border-white justify-center text-[8px] sm:text-[9px] font-bold -ml-3">+150
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
          <div className="py-16 sm:py-24 bg-white"><div className="max-w-xl mx-auto px-4 sm:px-0 lg:max-w-7xl lg:px-8"><div className="lg:text-center mb-12 sm:mb-16"><h2 className=" leading-10 font-bold tracking-tight text-gray-900 sm:tracking-tight text-3xl sm:text-5xl">Cum Funcționeaza?</h2></div><dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-16"><div><dt><div className="flex items-center justify-center h-12 w-12 rounded-md text-white text-xl font-bold bg-indigo-800">1</div><p className="mt-5 text-2xl leading-6 text-gray-900 font-bold">Selectează Documentul Dorit</p></dt><dd className="mt-2 text-gray-500 text-lg">Navighează prin lista noastră de servicii și selectează documentul de care ai nevoie. Echipa noastră de specialiști se va ocupa să furnizeze în cel mai scurt timp documentul solicitat.</dd></div><div><dt><div className="flex items-center justify-center h-12 w-12 rounded-md text-white text-xl font-bold bg-indigo-800">2</div><p className="mt-5 text-2xl leading-6 text-gray-900 font-bold">Completează datele</p></dt><dd className="mt-2 text-gray-500 text-lg">Completează formularul nostru online cu informațiile solicitate. Este simplu și ușor, iar datele tale sunt în siguranță cu noi.</dd></div><div><dt><div className="flex items-center justify-center h-12 w-12 rounded-md text-white text-xl font-bold bg-indigo-800">3</div><p className="mt-5 text-2xl leading-6 text-gray-900 font-bold">Primesti Documentul Rapid</p></dt><dd className="mt-2 text-gray-500 text-lg">După ce ai confirmat informațiile, procesăm cererea ta și îți trimitem documentul pe e-mail sau WhatsApp în funcție de opțiunea pe care ai ales-o.</dd></div></dl></div></div>
        <section className="bg-lightgrey dark:bg-gray-900"><div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6"><dl className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0"><div><div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full text-white bg-indigo-800 lg:h-12 lg:w-12"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-7 w-7"><path d="M6.111 11.89A5.5 5.5 0 1 1 15.501 8 .75.75 0 0 0 17 8a7 7 0 1 0-11.95 4.95.75.75 0 0 0 1.06-1.06Z"></path><path d="M8.232 6.232a2.5 2.5 0 0 0 0 3.536.75.75 0 1 1-1.06 1.06A4 4 0 1 1 14 8a.75.75 0 0 1-1.5 0 2.5 2.5 0 0 0-4.268-1.768Z"></path><path d="M10.766 7.51a.75.75 0 0 0-1.37.365l-.492 6.861a.75.75 0 0 0 1.204.65l1.043-.799.985 3.678a.75.75 0 0 0 1.45-.388l-.978-3.646 1.292.204a.75.75 0 0 0 .74-1.16l-3.874-5.764Z"></path></svg></div><h3 className="mb-2 text-xl font-bold dark:text-white">Eficiență Maximă</h3><p className="text-gray-500 dark:text-gray-400">Procesăm cererile într-un timp foarte scurt, astfel încât să primești documentele de care ai nevoie rapid și fără probleme.</p></div>
        <div><div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full text-white bg-indigo-800 lg:h-12 lg:w-12"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-7 w-7"><path fillRule="evenodd" d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 0 1-.82-1.256A8 8 0 0 1 18 9a22.47 22.47 0 0 1-1.228 7.351.75.75 0 1 1-1.417-.49A20.97 20.97 0 0 0 16.5 9 6.5 6.5 0 0 0 10 2.5ZM4.333 4.416a.75.75 0 0 1 .218 1.038A6.466 6.466 0 0 0 3.5 9a7.966 7.966 0 0 1-1.293 4.362.75.75 0 0 1-1.257-.819A6.466 6.466 0 0 0 2 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 0 1 1.038-.219ZM10 6.12a3 3 0 0 0-3.001 3.041 11.455 11.455 0 0 1-2.697 7.24.75.75 0 0 1-1.148-.965A9.957 9.957 0 0 0 5.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 0 1 8.996.084V9.15l-.005.297a.75.75 0 1 1-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 0 0-3-2.965Zm0 2.13a.75.75 0 0 1 .75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 1 1-1.186-.918A13.687 13.687 0 0 0 9.25 9a.75.75 0 0 1 .75-.75Zm3.529 3.698a.75.75 0 0 1 .584.885 18.883 18.883 0 0 1-2.257 5.84.75.75 0 1 1-1.29-.764 17.386 17.386 0 0 0 2.078-5.377.75.75 0 0 1 .885-.584Z" clipRule="evenodd"></path></svg></div><h3 className="mb-2 text-xl font-bold dark:text-white">Semnătură Electronică</h3><p className="text-gray-500 dark:text-gray-400">Documentele sunt semnate electronic, asigurând o transmitere rapidă și securizată, dar și autenticitatea acestora.</p></div><div><div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full text-white bg-indigo-800 lg:h-12 lg:w-12">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-7 w-7"><path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-1.503.204A6.5 6.5 0 1 1 7.95 3.83L6.927 5.62a1.453 1.453 0 0 0 1.91 2.02l.175-.087a.5.5 0 0 1 .224-.053h.146a.5.5 0 0 1 .447.724l-.028.055a.4.4 0 0 1-.357.221h-.502a2.26 2.26 0 0 0-1.88 1.006l-.044.066a2.099 2.099 0 0 0 1.085 3.156.58.58 0 0 1 .397.547v1.05a1.175 1.175 0 0 0 2.093.734l1.611-2.014c.192-.24.296-.536.296-.842 0-.316.128-.624.353-.85a1.363 1.363 0 0 0 .173-1.716l-.464-.696a.369.369 0 0 1 .527-.499l.343.257c.316.237.738.275 1.091.098a.586.586 0 0 1 .677.11l1.297 1.297Z" clipRule="evenodd"></path></svg></div><h3 className="mb-2 text-xl font-bold dark:text-white">Accesibil Oriunde</h3><p className="text-gray-500 dark:text-gray-400">Indiferent dacă ești în România sau în altă parte a lumii, poți accesa serviciile noastre.</p></div><div><div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full text-white bg-indigo-800 lg:h-12 lg:w-12"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-7 w-7"><path fillRule="evenodd" d="M2.5 4A1.5 1.5 0 0 0 1 5.5V6h18v-.5A1.5 1.5 0 0 0 17.5 4h-15ZM19 8.5H1v6A1.5 1.5 0 0 0 2.5 16h15a1.5 1.5 0 0 0 1.5-1.5v-6ZM3 13.25a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Zm4.75-.75a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 0-1.5h-3.5Z" clipRule="evenodd"></path></svg></div>
        <h3 className="mb-2 text-xl font-bold dark:text-white">Plăți Securizate</h3><p className="text-gray-500 dark:text-gray-400">Poți plăti cu cardul sau prin transfer bancar, alegând metoda care ți se potrivește cel mai bine.</p></div><div>
          <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full text-white bg-indigo-800 lg:h-12 lg:w-12">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="h-7 w-7" aria-hidden="true">
            <path d="M326.039 229.594c20.662 10.332 58.534-9.176 58.534-9.176C301.915 128.572 256.001 0 256.001 0s-45.916 128.572-128.573 220.418c0 0 37.872 19.509 58.538 9.176 0 0-20.666 79.215-113.64 183.691 82.642 22.948 144.634-14.936 144.634-14.936V512h78.083V398.348s61.992 37.884 144.634 14.936c-92.976-104.475-113.638-183.69-113.638-183.69z"></path></svg>
          </div>
        <h3 className="mb-2 text-xl font-bold dark:text-white">Facturare</h3><p className="text-gray-500 dark:text-gray-400">Primești factura în format electronic imediat după procesarea plății.</p></div>
        <div><div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full text-white bg-indigo-800 lg:h-12 lg:w-12"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-7 w-7"><path d="M5.127 3.502 5.25 3.5h9.5c.041 0 .082 0 .123.002A2.251 2.251 0 0 0 12.75 2h-5.5a2.25 2.25 0 0 0-2.123 1.502ZM1 10.25A2.25 2.25 0 0 1 3.25 8h13.5A2.25 2.25 0 0 1 19 10.25v5.5A2.25 2.25 0 0 1 16.75 18H3.25A2.25 2.25 0 0 1 1 15.75v-5.5ZM3.25 6.5c-.04 0-.082 0-.123.002A2.25 2.25 0 0 1 5.25 5h9.5c.98 0 1.814.627 2.123 1.502a3.819 3.819 0 0 0-.123-.002H3.25Z"></path></svg></div><h3 className="mb-2 text-xl font-bold dark:text-white">Obținerea documentelor prin Digigov</h3><p className="text-gray-500 dark:text-gray-400">Prin platforma noastră puteți obține o varietate de documente în doar câteva click-uri, verificând secțiunea Servicii.</p></div></dl></div></section>
    </div>
  );
};

export default LandingPage;
