import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { API_URL } from '../config';
const Return = ({idProcesareClient}) => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const [id, setId] = useState('');
  
    useEffect(() => {
      // Get the current URL pathname
      const path = window.location.pathname;
      // Split the path by '/' and get the element at index 2
      // assuming the URL format is always like /return/{id}
      const pathSegments = path.split('/');
      const idFromPath = pathSegments[2];
      setId(idFromPath);
      // Extract session_id from URL search parameters
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');

      fetch(`${API_URL}/session-status?session_id=${sessionId}&idProcesareClient=${idFromPath}`)
        .then(res => res.json())
        .then(data => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        })
        .catch(error => console.error('Failed to fetch session status:', error));
    }, []);  // Dependencies are empty since path and search won't change during component's lifecycle

    if (status === 'open') {
      return (
        <Navigate to={"/finalizare/"+idProcesareClient} />
      )
    }
  
    if (status === 'complete') {
      return (
        <div className="max-w-lg w-3/4 mx-auto p-6 bg-white rounded-lg shadow-md space-y-4">
        <label className="block text-gray-700 font-bold">Comandă realizată cu success!</label>
        <div className="flex flex-col max-w-lg w-full mx-auto m-2 p-6 bg-white rounded-lg shadow-md space-y-4 justify-items-center">
            <p>
               Ne bucurăm să lucrăm cu dumneavoastră!
              </p>
  
            Dacă aveți intrebări vă rugăm sa ne contactați la <a href="mailto:orders@example.com">contact@cazieronline.ro</a>

            O să revenim cu un apel în cel mai scurt timp!

            <button type="button" className="flex justify-center items-center py-2 px-6 border border-transparent rounded-xl shadow-sm text-md font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-violet-600 hover:bg-violet-700 focus:ring-violet-800 focus:border-violet-600 text-white disabled:bg-gray-400">Vă multumim!</button>
              

          </div></div>
      )
    }
  
    return null;
  }

export default Return