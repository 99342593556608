import logo from '../assets/logo192.png';
import cards from '../cards.webp';

export default () => (<>
    <footer className="bg-indigo-800 w-full p-4 sm:px-6 sm:py-12"><div className="mx-auto max-w-screen-xl"><div className="md:flex">
      <div className="mb-6 md:mb-0"><a className="flex items-center" href="/">
      <div className="flex items-center space-x-4">
          <img src={logo} alt="Logo" className="h-8 w-8" />
          <h1 className="text-xl font-semibold text-white">Cazio</h1>
        </div>
      </a></div><div className="sm:flex gap-8 md:gap-12 lg:gap-16 ml-auto">
        <div>
          <ul className="text-left font-medium justify-left">
            <li className="mb-4"><a className="text-white hover:text-gray-300 hover:text-white duration-100" href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noreferrer">Soluționarea Alternativă a Litigiilor</a></li>
            <li className="mb-4"><a className="text-white hover:text-gray-300 hover:text-white duration-100" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=RO" target="_blank" rel="noreferrer">Soluționarea Online a Litigiilor</a></li>
            <li className="mb-4"><a className="text-white hover:text-gray-300 hover:text-white duration-100" href="/termeni-si-conditii">Termeni și condiții</a></li>
      </ul></div>
      <div><ul className="text-left font-medium"><li className="mb-4"><a className="text-white hover:text-gray-300 hover:text-white duration-100" href="/politica-cookies">Politica de cookie-uri</a></li><li className="mb-4"><a className="text-white hover:text-gray-300 hover:text-white duration-100" href="/politica-de-confidentialitate">Politica de confidențialitate</a></li><li className="mb-4"><a className="text-white hover:text-gray-300 hover:text-white duration-100" href="/gdpr">GDPR</a></li></ul></div><div>
        <ul className="text-left font-medium"><li className="mb-4"><a className="text-white hover:text-gray-300 hover:text-white duration-100" href="/about">Despre Noi</a></li><li className="mb-4">
      <a className="text-white hover:text-gray-300 hover:text-white duration-100" href="/">Comandă Acum</a></li><li className="mb-4"><a className="text-white hover:text-gray-300 hover:text-white duration-100" href="/contact">Contactaţi-ne</a></li></ul></div>
      </div></div><hr className="my-6 border-white border-opacity-40 sm:mx-auto lg:my-8"/><div className="sm:flex sm:items-center sm:justify-between"><span className="text-sm text-gray-200 sm:text-center">© 2024 DigiGov.ro Toate drepturile rezervate.</span><div className="flex mt-4 sm:justify-center sm:mt-0 w-48"><img alt="Supported cards: Visa, Mastercard, Maestro, American Express" loading="lazy" width="1039" height="150" decoding="async" data-nimg="1" className="h-6" style={{color:"transparent"}} src={cards}/></div></div></div>
    </footer>
  </>);