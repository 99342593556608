import React, { useCallback, useEffect, useState } from 'react';
import ToggleButton from './../components/ToggleButton';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../config';
import axios from 'axios';

// Functie pentru validarea email-ului
function validateEmail(email) {
   // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

function validatePhone(phone) {
  // Regex to match international phone numbers
  const re = /^\+?[0-9]\d{1,14}$/;  // This is a simplified, general pattern for international numbers
  return re.test(phone);
}

function validateFields(field, fieldData){
  let validationResult = true;
  if (field === "legalEntity") return true;
  if (fieldData === "" || fieldData === null || fieldData === undefined) return false;
  if (field === "email") validationResult = validateEmail(fieldData);
  else if (field === "phone") validationResult = validatePhone(fieldData);
  return validationResult && fieldData.trim() !== '';
}



function InvoiceForm({ setProgress, setSubmitedData, submitted, raiseError }) {
    const { id: idProcesareClient } = useParams();
    const [formType, setFormType] = useState('civilian');
    const [formData, setFormData] = useState({
      facturare_phone: '',
      facturare_firstName: '',
      facturare_lastName: '',
      facturare_judet: '',
      facturare_oras: '',
      facturare_adresa: '',
      facturare_CUI_firma: '',
      facturare_firma: '',
      facturare_phone_firma: '',
      facturare_judet_firma: '',
      facturare_oras_firma: '',
      facturare_adresa_firma: '',
    });
    const [formErrors, setFormErrors] = useState({});
  
    const labels = {
      facturare_firstName: 'Prenume',
      facturare_lastName: 'Nume de familie',
      facturare_phone: 'Număr de telefon',
      facturare_judet: 'Județ',
      facturare_oras: 'Localitatea',
      facturare_adresa: 'Adresă',
      facturare_CUI_firma: 'CUI-ul firmei pentru care va fi emisă factura',
      facturare_firma: 'Nume Firmă',
      facturare_phone_firma: 'Număr de telefon',
      facturare_judet_firma: 'Județul',
      facturare_oras_firma: 'Localitatea',
      facturare_adresa_firma: 'Adresă',
    };
  
    const handleFormTypeChange = () => {
      setFormType(prevType => (prevType === 'civilian' ? 'firm' : 'civilian'));
      setFormData(Object.fromEntries(Object.keys(formData).map(key => [key, ''])));
      setProgress(0);
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      const isValid = validateFields(name, value);
      setFormData({ ...formData, [name]: value });
      setFormErrors({ ...formErrors, [name]: !isValid });
    };
  
    useEffect(() => {
      checkValidity();
        const fieldsToCheck = formType === 'civilian'
        ? [
          'facturare_phone',
          'facturare_firstName',
            'facturare_lastName',
               'facturare_judet',
                'facturare_oras',
              'facturare_adresa']
        : [
          'facturare_phone_firma',
          'facturare_judet_firma',
           'facturare_oras_firma',
          'facturare_adresa_firma',
                 'facturare_CUI_firma',
               'facturare_firma',];
        const filledFields = fieldsToCheck.filter(field => validateFields(field, formData[field])).length;
        const prog = Math.min((filledFields / fieldsToCheck.length) * 100, 100);
        setProgress(prog);
        if (prog === 100 && Object.values(fieldsToCheck).every(e => !formErrors[e])) {
          console.log("all correct, submitting", formData);
          setSubmitedData(formData);
          raiseError(false);
        }
    }, [formData, formType]);
  
    const checkValidity = useCallback(() => {
      const newFormErrors = {};
      Object.keys(formData).forEach(key => {
        newFormErrors[key] = !validateFields(key, formData[key]);
      });
      setFormErrors(newFormErrors);
    }, [formData]);
  
    const renderFormFields = (livrare) => {
      const fieldsToShow = livrare === true ? Object.keys(formData).filter(key => !key.startsWith('facturare_')) : Object.keys(formData).filter(key => !key.startsWith('livrare_')).filter(key=> {if (formType === 'civilian' && !key.endsWith('_firma')) return key; else if (formType !== 'civilian' && key.endsWith('_firma')) return key});
      return fieldsToShow.map(key => {
        const spanCol = key.includes('facturare_phone') || key.includes('facturare_adresa') || key.includes('facturare_firma') ? "col-span-12" : "col-span-6";
        if (key.includes("facturare_CUI_firma")) return (<div className="col-span-12">
          <div className='col-span-12'>
            <label
              htmlFor="firmCUI"
              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
            >
              CUI-ul firmei pentru care va fi emisă factura
            </label>
            <div className="flex gap-4">
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
                placeholder={`Enter ${labels[key]}`}
                className={`mt-1 p-2 border ${
                  formErrors[key]
                    ? 'border-red-500'
                    : 'border-gray-300'
                } w-full rounded bg-gray-100 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400`}
                required
              />
              <button
                type="button"
                className="flex justify-center items-center py-2 px-6 border border-transparent rounded-xl shadow-sm text-md font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-violet-600 hover:bg-violet-700 focus:ring-violet-800 focus:border-violet-600 text-white disabled:bg-gray-400"
                >
                Caută
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="w-5 h-5 ml-1 rotate-[80deg]"
                  >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    ></path>
                </svg>
              </button>
            </div>
          </div>
                    {submitted && formErrors[key] && <p className="text-red-500 text-xs italic">Please enter a valid {labels[key]}.</p>}
        </div>);

        if (key.includes("facturare_judet_firma")) return (<div className="col-span-6 relative handle-outside-click">
          <label
            htmlFor="facturare_judet_firma"
            className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
          >
            Județul
          </label>
          <select className="input" name="facturare_judet_firma"
          value={formData[key]} 
          onChange={handleChange} >
            <option value="">Alege un județ</option>
            <option value="AB">Alba</option>
            <option value="AR">Arad</option>
            <option value="AG">Argeş</option>
            <option value="BC">Bacău</option>
            <option value="BH">Bihor</option>
            <option value="BN">Bistriţa-Năsăud</option>
            <option value="BT">Botoşani</option>
            <option value="BR">Brăila</option>
            <option value="BV">Braşov</option>
            <option value="B">Bucureşti</option>
            <option value="BZ">Buzău</option>
            <option value="CL">Călăraşi</option>
            <option value="CS">Caraş-Severin</option>
            <option value="CJ">Cluj</option>
            <option value="CT">Constanţa</option>
            <option value="CV">Covasna</option>
            <option value="DB">Dâmboviţa</option>
            <option value="DJ">Dolj</option>
            <option value="GL">Galaţi</option>
            <option value="GR">Giurgiu</option>
            <option value="GJ">Gorj</option>
            <option value="HR">Harghita</option>
            <option value="HD">Hunedoara</option>
            <option value="IL">Ialomiţa</option>
            <option value="IS">Iaşi</option>
            <option value="IF">Ilfov</option>
            <option value="MM">Maramureş</option>
            <option value="MH">Mehedinţi</option>
            <option value="MS">Mureş</option>
            <option value="NT">Neamţ</option>
            <option value="OT">Olt</option>
            <option value="PH">Prahova</option>
            <option value="SJ">Sălaj</option>
            <option value="SM">Satu Mare</option>
            <option value="SB">Sibiu</option>
            <option value="SV">Suceava</option>
            <option value="TR">Teleorman</option>
            <option value="TM">Timiş</option>
            <option value="TL">Tulcea</option>
            <option value="VL">Vâlcea</option>
            <option value="VS">Vaslui</option>
            <option value="VN">Vrancea</option>
          </select>
        </div>
        );

        if (key.includes("facturare_judet")) return (<div className="col-span-6 relative handle-outside-click">
          <label
            htmlFor="facturare_judet"
            className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
          >
            Județul
          </label>
          <select className="input" name="facturare_judet"
          value={formData[key]} 
          onChange={handleChange} >
            <option value="">Alege un județ</option>
            <option value="AB">Alba</option>
            <option value="AR">Arad</option>
            <option value="AG">Argeş</option>
            <option value="BC">Bacău</option>
            <option value="BH">Bihor</option>
            <option value="BN">Bistriţa-Năsăud</option>
            <option value="BT">Botoşani</option>
            <option value="BR">Brăila</option>
            <option value="BV">Braşov</option>
            <option value="B">Bucureşti</option>
            <option value="BZ">Buzău</option>
            <option value="CL">Călăraşi</option>
            <option value="CS">Caraş-Severin</option>
            <option value="CJ">Cluj</option>
            <option value="CT">Constanţa</option>
            <option value="CV">Covasna</option>
            <option value="DB">Dâmboviţa</option>
            <option value="DJ">Dolj</option>
            <option value="GL">Galaţi</option>
            <option value="GR">Giurgiu</option>
            <option value="GJ">Gorj</option>
            <option value="HR">Harghita</option>
            <option value="HD">Hunedoara</option>
            <option value="IL">Ialomiţa</option>
            <option value="IS">Iaşi</option>
            <option value="IF">Ilfov</option>
            <option value="MM">Maramureş</option>
            <option value="MH">Mehedinţi</option>
            <option value="MS">Mureş</option>
            <option value="NT">Neamţ</option>
            <option value="OT">Olt</option>
            <option value="PH">Prahova</option>
            <option value="SJ">Sălaj</option>
            <option value="SM">Satu Mare</option>
            <option value="SB">Sibiu</option>
            <option value="SV">Suceava</option>
            <option value="TR">Teleorman</option>
            <option value="TM">Timiş</option>
            <option value="TL">Tulcea</option>
            <option value="VL">Vâlcea</option>
            <option value="VS">Vaslui</option>
            <option value="VN">Vrancea</option>
          </select>
        </div>
        );
        return (
        <div key={key} className={spanCol}>
          <label className="block text-gray-700">{labels[key]}</label>
          <input
            type={key.includes('email') ? 'email' : 'text'}
            name={key}
            value={formData[key]}
            onChange={handleChange}
            placeholder={`Enter ${labels[key]}`}
            className={`mt-1 p-2 border ${formErrors[key] ? 'border-red-500' : 'border-gray-300'} w-full rounded bg-gray-100 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400`}
            required
          />
          {submitted && formErrors[key] && <p className="text-red-500 text-xs italic">Please enter a valid {labels[key]}.</p>}
        </div>
      )});
    };
  
    return (
      <div className="mx-auto max-w-7xl pb-10 sm:py-16 sm:px-6 lg:px-8 w-full grow">
      <div className="flex rounded-md py-4 md:py-8 items-center bg-white justify-center">
          <form className="w-full lg:w-4/6 px-4 md:px-8 py-4">
              <div className="flex flex-col gap-6 mb-6">
                  <h1 className="font-semibold text-2xl">Facturare</h1>
                  <div className="w-full p-4 rounded-lg shadow-sm border-2 border-gray-500/10">
                      <div className="flex flex-col md:grid md:grid-cols-12 gap-3 md:gap-6">
                          <div className="col-span-12">
                              <ToggleButton
                                  leftSelected={formType === 'civilian'}
                                  handleFormTypeChange={handleFormTypeChange}
                                  leftText="Persoana Fizica"
                                  rightText="Persoana Juridica"
                              />
                          </div>
                          {renderFormFields(formType === 'legal')}
                          <div className="col-span-12">
                              <div className="flex items-center cursor-pointer select-none">
                                  <input className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 cursor-pointer text-indigo-600 focus:ring-indigo-300" type="checkbox" name="terms_and_conditions"/>
                                  <div className="ml-2">
                                      <div className="text-base sm:text-sm">Am luat la cunoștință și sunt de acord cu  <a className="underline hover:no-underline text-indigo-800 hover:text-indigo-950" href="/privacy-policy">Politica de confidențialitate</a> și 
                                      <a className="underline hover:no-underline text-indigo-800 hover:text-indigo-950" href="/terms-and-conditions">Termenii si Condițiile</a> acestui site. Împuternicesc un reprezentant digigov.ro să solicite în numele meu cazierul judiciar.
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </form>
      </div>
  </div>
  );
  }

export const InvoiceFooter = ({ progress, idProcesareClient, formData, raiseError}) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (progress === 100)
    axios.put(API_URL+'/api/updateClient', {
      formData: {billing: {...formData}},
      idProcesareClient,
    })
    .then(response => {
      console.log(response.data);
      if (!response.data.error) {
        navigate("/finalizare/"+ idProcesareClient);
        raiseError(false);
      } else {
        console.error('Error:', response.data.error);
        raiseError(true);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      raiseError(true);
    })
    else {
      raiseError(true);
    }
  };

  return (
      <footer className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex flex-row gap-4 items-center shadow-lg shadow-gray-500/50">
        <div className="w-full bg-gray-200 rounded-full h-4">
        <div
            className={`relative bg-green-600 h-4 rounded-full transition-all ease-in-out duration-500 ${progress === 100 ? 'animate-pulse-green' : ''}`}
            style={{ width: `${progress}%` }}  
          >
          <div className="wave"></div> 
        </div>
        </div>
        <button
          onClick={handleSubmit}
          className={`bg-violet-600 text-white py-2 px-4 rounded ${progress === 100 ? 'animate-pulse-green' : ''} hover:bg-violet-700 hover:shadow-lg transition duration-300`}
        >
          Urmatorul Pas
        </button>
      </footer>
    );
} 

export default InvoiceForm;
