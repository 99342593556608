import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PaymentBox = ({ }) => {
    const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      alert('Payment failed: ' + error.message);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      alert('Payment successful!');
      // Here you can call your backend to save the payment method, finalize payment, etc.
    }
  };

  return (
    <div className="rounded-md bg-white flex flex-col justify-center items-center shadow-xl border-2 border-indigo-300">
        <div className="flex items-center justify-between border-b-2 w-full p-4 border-indigo-300">
        <strong className="text-gray-700 text-xl px-2">Total de plată:</strong>
        <strong className="text-gray-700 text-xl px-2">549.78 lei</strong>
        </div>
        <div className="pt-6 px-4 pb-4 w-full grow">
            <form className="w-full h-full flex flex-col justify-between"  onSubmit={handleSubmit}>
            
            
            <div>
                <button id="submit" type="submit" disabled={!stripe} className="w-full py-3 px-4 rounded-md shadow-sm text-md border font-bold uppercase relative mt-4 bg-fuchsia-600 hover:bg-fuchsia-700 focus:ring-fuchsia-800 focus:border-fuchsia-600 text-white disabled:bg-gray-400">
                    <span className="flex items-center justify-center gap-4">Plătește acum</span></button>
                <div className="flex gap-2 px-2 text-xs items-center font-semibold text-gray-600 mt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 stroke-green-600">
                    <path stroke-linecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"></path>
                    </svg>Aceasta este o plată securizată criptată de 256 de biți.</div></div>
                    </form>
            </div>
    </div>
  );
};

export default PaymentBox;
