import React, { useCallback, useEffect, useState } from 'react';
import ToggleButton from './ToggleButton';  // Import the ToggleButton component
import { useNavigate, useParams } from 'react-router-dom';
import MotivSolicitare from './MotivSolicitare';
import { API_URL } from '../config';
import axios from 'axios';

// Functie pentru validarea email-ului
function validateEmail(email) {
   // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

function validatePhone(phone) {
  // Regex to match international phone numbers
  const re = /^\+?[0-9]\d{1,14}$/;  // This is a simplified, general pattern for international numbers
  return re.test(phone);
}

// Functie pentru validarea CNP-ului
function validateCNP(cnp) {
  if (cnp.length !== 13 || !cnp.match(/^\d+$/)) {
    return false;
  }
  return true;
}

function validateFields(field, fieldData){
  let validationResult = true;
  if (fieldData === "" || fieldData === null || fieldData === undefined) return false;
  if (field === "legalEntity") return true;
  if (field === "isForeignCitizen") return true;
  if (field === "email") validationResult = validateEmail(fieldData);
  else if (field === "phone") validationResult = validatePhone(fieldData);
  else if (field === "id") validationResult = validateCNP(fieldData);

  return validationResult && fieldData.trim() !== '';
}


const ClientForm = ({ setProgress, setSubmitedData, submitted, raiseError }) => {
  const {id: idProcesareClient} = useParams();
  const [formType, setFormType] = useState('civilian');
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    fatherName: false,
    motherName:false,
    isForeignCitizen: false,
    firmCUI: false,
    firmName: false,
    adminName: false,
    motive: false,
    legalEntity: false,
  });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    fatherName: '',
    motherName: '',
    isForeignCitizen: false,
    firmCUI: '',
    firmName: '',
    adminName: '',
    motive: '',
    legalEntity: false
  });

  const handleFormTypeChange = () => {
    let clientLegalEntity = false;
    if (formType === 'civilian'){
      setFormType('firm');
      clientLegalEntity = true;
    }
    else setFormType('civilian');
    setFormData({
      ...formData,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      fatherName: '',
      motherName: '',
      isForeignCitizen: false,
      firmCUI: '',
      firmName: '',
      adminName: '',
      motive: '',
      legalEntity: clientLegalEntity
    });
    setProgress(0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "isForeignCitizen") {
      setFormData({ ...formData, [name]: !formData[name] });
      return;
    }
    const isValid = validateFields(name, value);
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: !isValid });
  };

  useEffect(() => {
    // Update the progress and check if form is fully valid
    const fieldsToCheck = formType === 'civilian'
    ? ['firstName', 'lastName', 'email', 'phone',  'fatherName','motherName','isForeignCitizen', 'motive']
    : ['email', 'phone', 'firmCUI', 'firmName', 'adminName', 'motive'];
    const filledFields = fieldsToCheck.filter(field => validateFields(field, formData[field])).length;
    const prog = Math.min((filledFields / fieldsToCheck.length) * 100, 100);
    setProgress(prog);
    if (prog === 100 && Object.values(fieldsToCheck).every(e => !formErrors[e])) {
      console.log("all correct, submitting", formData);
      setSubmitedData(formData);
      raiseError(false);
    }
  }, [formData, formErrors, formType, raiseError, setProgress, setSubmitedData]);

  const checkValidity = useCallback(() => {
    Object.keys(formData).forEach(key => {
      const isValid = validateFields(key, formData[key]); // Assume validateFields is defined
      // Use functional update to avoid dependency on formErrors
      setFormErrors(prevFormErrors => ({ ...prevFormErrors, [key]: !isValid }));
    });
  }, [formData]); 
  
  useEffect(() => {
    if (submitted) {
      checkValidity();
    }
  }, [submitted, checkValidity]); 

  return (
    <div className="mx-auto max-w-7xl pb-10 sm:py-16 sm:px-6 lg:px-8 w-full grow transition duration-500">
      <div>
        <div className="flex rounded-md py-4 md:py-8 items-center bg-white justify-center">
          <form className="w-full lg:w-4/6 px-4 md:px-8 py-4">
            <div>
              <div className="flex flex-col gap-6 mb-6">
                <h1 className="font-semibold text-2xl text-left">Informații Personale</h1>
                <div className="w-full p-4 rounded-lg shadow-sm border-2 border-gray-500/10">
                  <div className="flex flex-col md:grid md:grid-cols-12 gap-3 md:gap-6">
                    <div className="col-span-12">
                      <ToggleButton leftSelected={formType === 'civilian'} handleFormTypeChange={handleFormTypeChange} leftText={"Persoană Fizică"} rightText={"Persoană Juridică"}/>
                    </div>
                    {formType === 'civilian' ? (
                      <>
                        <div className="col-span-12">
                          <div>
                            <label
                              htmlFor="email"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              E-mail
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Adresa de e-mail"
                                className="input !text-base sm:!text-sm"
                                type="email"
                                value={formData.email}
                                name="email"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-6">
                          <div>
                            <label
                              htmlFor="firstName"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Prenume
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Introdu prenumele tău"
                                className="input !text-base sm:!text-sm"
                                type="text"
                                value={formData.firstName}
                                name="firstName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-6">
                          <div>
                            <label
                              htmlFor="lastName"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Nume
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Introdu numele tău"
                                className="input !text-base sm:!text-sm"
                                type="text"
                                value={formData.lastName}
                                name="lastName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12">
                          <div>
                            <label
                              htmlFor="phone"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Telefon
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Numărul de telefon"
                                className="input !text-base sm:!text-sm"
                                type="text"
                                value={formData.phone}
                                name="phone"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-6">
                          <div>
                            <label
                              htmlFor="fatherName"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Numele tatălui
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Numele tatălui"
                                className="input !text-base sm:!text-sm"
                                type="text"
                                value={formData.fatherName}
                                name="fatherName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-6">
                          <div>
                            <label
                              htmlFor="motherName"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Numele mamei
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Numele mamei"
                                className="input !text-base sm:!text-sm"
                                type="text"
                                value={formData.motherName}
                                name="motherName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 w-full flex justify-center">
                          <div className="sm:my-3 text-sm">
                            <div className="flex items-center cursor-pointer select-none">
                              <input
                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 cursor-pointer text-indigo-600 focus:ring-indigo-300"
                                type="checkbox"
                                name="isForeignCitizen"
                                onChange={handleChange}
                                checked={formData.isForeignCitizen}
                              />
                              <div className="ml-2">
                                <label
                                  htmlFor="isForeignCitizen"
                                  className="block text-base sm:text-sm font-medium text-gray-900 cursor-pointer"
                                >
                                  Sunt cetățean străin
                                </label>
                              </div>
                            </div>
                            <div className="w-fit" />
                          </div>
                        </div>
                        <div className="col-span-12">
                          <MotivSolicitare formData={formData} handleChange={handleChange} formErrors={formErrors}/>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-span-12">
                          <div>
                            <label
                              htmlFor="email"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Email
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Enter email"
                                className="input !text-base sm:!text-sm"
                                type="email"
                                value={formData.email}
                                name="email"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12">
                          <div>
                            <label
                              htmlFor="phone"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Phone
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Enter phone number"
                                className="input !text-base sm:!text-sm"
                                type="tel"
                                value={formData.phone}
                                name="phone"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12">
                          <div>
                            <label
                              htmlFor="firmCUI"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              CUI-ul firmei pentru care va fi eliberat cazierul
                            </label>
                            <div className="flex gap-4">
                              <input
                                type="text"
                                name="firmCUI"
                                value={formData.firmCUI}
                                onChange={handleChange}
                                placeholder="Enter CUI"
                                className={`mt-1 p-2 border ${
                                  formErrors.firmCUI
                                    ? 'border-red-500'
                                    : 'border-gray-300'
                                } w-full rounded bg-gray-100 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400`}
                                required
                              />
                              <button
                                type="button"
                                className="flex justify-center items-center py-2 px-6 border border-transparent rounded-xl shadow-sm text-md font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-violet-600 hover:bg-violet-700 focus:ring-violet-800 focus:border-violet-600 text-white disabled:bg-gray-400"
                              >
                                Caută
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                  data-slot="icon"
                                  className="w-5 h-5 ml-1 rotate-[80deg]"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12">
                          <div>
                            <label
                              htmlFor="firmName"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Nume Firmă
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Enter firm name"
                                className="input !text-base sm:!text-sm"
                                type="text"
                                value={formData.firmName}
                                name="firmName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12">
                          <div>
                            <label
                              htmlFor="adminName"
                              className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
                            >
                              Nume Administrator
                            </label>
                            <div className="relative">
                              <input
                                placeholder="Enter admin name"
                                className="input !text-base sm:!text-sm"
                                type="text"
                                value={formData.adminName}
                                name="adminName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export const ClientFooter = ({ progress, idProcesareClient, formData, raiseError}) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (progress === 100) {
      axios.post(API_URL+'/api/uploadClientInfo', {
        formData,
        idProcesareClient,
      })
      .then(response => {
        console.log(response.data);
        if (!response.data.error) {
          navigate("/optiuni/"+ idProcesareClient);
          raiseError(false);
        } else {
          console.error('Error:', response.data.error);
          raiseError(true);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        raiseError(true);
      });
    } else {
      raiseError(true);
    }
  };

  return (
      <footer className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex flex-row gap-4 items-center shadow-lg shadow-gray-500/50">
        <div className="w-full bg-gray-200 rounded-full h-4">
        <div
            className={`relative bg-green-600 h-4 rounded-full transition-all ease-in-out duration-500 ${progress === 100 ? 'animate-pulse-green' : ''}`}
            style={{ width: `${progress}%` }}  
          >
          <div className="wave"></div> 
        </div>
        </div>
        <button
          onClick={handleSubmit}
          className={`bg-violet-600 text-white py-2 px-4 rounded ${progress === 100 ? 'animate-pulse-green' : ''} hover:bg-violet-700 hover:shadow-lg transition duration-300`}
        >
          Urmatorul Pas
        </button>
      </footer>
    );
} 

export default ClientForm;
