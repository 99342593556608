import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet, Font } from '@react-pdf/renderer';

// Register Roboto font with different weights
Font.register({
    family: 'Roboto',
    fonts: [
      { src: require('./../assets/fonts/Roboto-Regular.ttf'), fontWeight: 'normal' },
      { src: require('./../assets/fonts/Roboto-Medium.ttf'), fontWeight: 'medium' },
      { src: require('./../assets/fonts/Roboto-Bold.ttf'), fontWeight: 'bold' },
      { src: require('./../assets/fonts/Roboto-Italic.ttf'), fontWeight: 'normal', fontStyle: 'italic' }
    ]
  });

// Create styles for the PDF document
const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 30,
      fontWeight: 'normal'
   
  },
  section: {
      fontWeight: 'medium',
      fontSize: 12,
  },
  title: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 10,
      fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 20,
  },
  text: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 10,
    lineHeight: 1.5,
    fontWeight: 'normal'
  },
  signatures: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  signatureBlock: {
    width: '40%', // adjust based on your layout needs
    marginRight: 20,
  },
  signatureImage: {
    width: 50, // or any other dimensions you prefer
    height: 50,
    marginBottom: 5,
    marginLeft:80,
  },
  signatureText: {
    fontSize: 12,
    textAlign: 'center', // Centers the text below the image
    marginTop: 5,
  }
});

const ContractPDF = ({ userData, prestatorSignature, beneficiarSignature }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>CONTRACT DE PRESTĂRI SERVICII</Text>
          <Text style={styles.subtitle}>Nr. 351382 din data de 14/07/2024</Text>
          <Text style={styles.section}>01. PĂRȚILE</Text>
          <Text style={styles.text}>
            1.1. WISE SLICE SRL-D, cu sediul în Municipiul Bucureşti, Sector 2, Str. Barbu
            Văcărescu, Nr. 143, Et. 1, Ap. 2, înregistrată la Registrul Comerțului J40/7878/2020, CUI
            42763857, IBAN RO39BTRLRONCRT0556829201, deschis la Banca Transilvania, denumită în
            cele ce urmează „Prestatorul”, și
          </Text>
          {userData.firmName !== "" ? 
          <Text style={styles.text}>
            1.2. {userData.firmName}, având email {userData.email} și număr de telefon {userData.phone}, denumit/ă în cele
            ce urmează „Benefciarul”.
          </Text> : 
          <Text style={styles.text}>
            1.2. {userData.firstName} {userData.lastName}, având email {userData.email} și număr de telefon {userData.phone}, denumit/ă în cele
            ce urmează „Benefciarul”.
          </Text>}
          <Text style={styles.section}>02. OBIECTUL</Text>
          <Text style={styles.text}>
            Contractul intră în vigoare la data semnării acestuia și își produce efectele până la data
            îndeplinirii obiectului, respectiv până la finalizarea procedurilor specifice obiectului contractului.
          </Text>
          <Text style={styles.section}>04. PREȚUL</Text>
          <Text style={styles.text}>
            Pentru serviciile prestate în temeiul prezentului Contract, Benefciarul se obligă sa plătească
            Prestatorului suma de lei afișată în comanda plasată. Suma va putea fi plătită prin transfer
            bancar sau online.
          </Text>
          <Text style={styles.section}>05. CONFIDENȚIALITATEA ȘI PRELUCRAREA DATELOR CU CARACTER PERSONAL</Text>
          <Text style={styles.text}>
            Părțile se obligă sa păstreze confidențialitatea informațiilor de care iau cunoștință pe parcursul
            derulării prezentului contract și după încetarea contractului. Benefciarul înțelege și acceptă
            faptul că pentru executarea obligațiilor asumate prin Contract este necesară dezvăluirea și
            prelucrarea unor date cu caracter personal, precum nume, prenume, adresa de e-mail, număr de
            telefon, carte de identitate/pașaport, permis de muncă, numele membrilor de familie (tată,
            mamă), semnătură. Prestatorul va prelucra datele cu caracter personal într-un mod confidențial
            și responsabil.
          </Text>
          <Text style={styles.text}>06. ÎNCETAREA</Text>
          <Text style={styles.text}>
            Contractul încetează în cazul în care obiectul contractului a fost îndeplinit; prin acordul scris al
            părților; ca efect al rezilierii, în caz de neexecutare sau executare necorespunzătoare a
            obligațiilor asumate prin acest contract de către una din părți, cealaltă parte contractantă are
            dreptul să considere prezentul contract reziliat de plin drept, fără a fi necesară îndeplinirea
            vreunei formalități prealabile sau intervenția instanței judecătorești; în alte cazuri prevăzute de
            lege.
          </Text>
          <Text style={styles.text}>07. TERMENE DE LIVRARE</Text>
          <Text style={styles.text}>
            Solicitările depuse în regim urgent au termen de livrare în format electronic de 1-2 zile lucrătoare.
            Pentru livrarea în formă fizică a actelor în original, termenul de livrare este de maxim 3 zile
            lucrătoare începând cu ziua următoare depunerii solicitării. Solicitările depuse în regim normal au
            termen de livrare în format electronic de 5 zile lucrătoare.
          </Text>
          <Text style={styles.text}>08. ALTE DISPOZIȚII</Text>
          <Text style={styles.text}>
            Legea care guvernează raporturile juridice născute între părți în temeiul prezentului contract este
            legea română. Eventualele neînțelegeri intervenite între părți în legătură cu interpretarea
            prezentului contract sau cu executarea obligațiilor decurgând din prezentul contract care nu au
            fost soluționate pe cale amiabilă vor fi supuse soluționării instanțelor judecătorești competente
            de la sediul Prestatorului.
          </Text>
          <Text style={styles.text}>08. ALTE DISPOZIȚII</Text>
          <Text style={styles.text}>
            Legea care guvernează raporturile juridice născute între părți în temeiul prezentului contract este
            legea română. Eventualele neînțelegeri intervenite între părți în legătură cu interpretarea
            prezentului contract sau cu executarea obligațiilor decurgând din prezentul contract care nu au
            fost soluționate pe cale amiabilă vor fi supuse soluționării instanțelor judecătorești competente
            de la sediul Prestatorului.
          </Text>
          <Text style={styles.boldText}>09. CLAUZE FINALE</Text>
          <Text style={styles.text}>
            Acest contract poate fi modificat doar prin acordul scris al ambelor părți, cu excepția cazurilor
            prevăzute expres de lege. Niciuna dintre părți nu poate ceda drepturile și obligațiile sale
            contractuale fără acordul prealabil scris al celeilalte părți.
          </Text>
          <Text style={styles.text}>
            Fiecare parte garantează că are dreptul și autoritatea necesară pentru a încheia acest contract.
            Acest contract constituie acordul integral între părți în ceea ce privește obiectul său și
            înlocuiește toate comunicările, negocierile și acordurile anterioare, fie scrise fie orale.
          </Text>
          <Text style={styles.footer}>
            Întocmit și încheiat la distanță, astăzi, data semnării.
          </Text>
          <View style={styles.signatures}>
          <View style={styles.signatureBlock}>
            {beneficiarSignature && (
              <Image src={beneficiarSignature} style={styles.signatureImage} />
            )}
            <Text style={styles.signatureText}>
            ____________</Text>
            <Text style={styles.signatureText}>
                PRESTATOR</Text>
          </View>
          <View style={styles.signatureBlock}>
            {beneficiarSignature && (
              <Image src={beneficiarSignature} style={styles.signatureImage} />
            )}
            <Text style={styles.signatureText}>
            _____________</Text>
            <Text style={styles.signatureText}>
                BENEFICIAR</Text>
          </View>
        </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>CONTRACT DE ASISTENŢĂ JURIDICĂ</Text>
          <Text style={styles.subtitle}>Seria B Nr. ........................ din data de 14/07/2024 </Text>
          <Text style={styles.text}>Încheiat între:</Text>
          <Text style={styles.text}>
          1. Denumirea formei de exercitare a profesiei PETRE ROȘU ȘI ASOCIAȚII – SOCIETATE CIVILĂ 
DE AVOCAȚI, înfințată prin decizia Baroului București nr. 823 din 2021 , cu sediul în București, 
Str Stanilesti Nr 56 Sector 1, având codul fscal nr. RO44297699 prin avocat Laurențiu Petre în 
calitate de Avocat coordonator, pe de o parte, şi
          </Text>
          <Text style={styles.text}>
          2. Domnul/Doamna Gabi Gavril , domiciliat(ă) în în calitate de CLIENT / CLIENTA, pe de altă 
parte.
În conformitate cu prevederile Legii nr. 51/1995 pentru organizarea şi exercitarea profesiei de 
avocat, republicată, cu modifcările ulterioare, denumită în continuare Lege, şi ale statutului 
profesiei de avocat, părţile convin:
          </Text>
          <Text style={styles.subtitle}>ARTICOLUL 1 - Obiectul contractului</Text>
          <Text style={styles.text}>
          1.1.Obiectul contractului: reprezentarea clientului în fața autorităților competente în vederea 
solicitării și ridicării cazierului judiciar si/sau a certifcatului de integritate comportamentala.
1.2. Alte menţiuni: - 
          </Text>
          <Text style={styles.subtitle}>ARTICOLUL 2 - Onorariul</Text>
          <Text style={styles.text}>
          2.1. Onorariul convenit este în cuantum de ......lei si se va achita in contul Societatii Everything 
        Data Solutions SRL, CUI 42763857, J40/7878/2020, Str. Barbu Vacarescu, Nr. 143, Etaj 2, Sector 
        2, Bucuresti, Romania urmand ca aceasta din urma sa achite in conditiile stabilite contractual, 
        contravaloarea serviciilor avocatiale. Cont IBAN este: RO39BTRLRONCRT0556829201 deschis la 
        Banca Transilvania.
        </Text>
        <Text style={styles.text}>
        2.2. Alte menţiuni cu privire la onorariu [se poate preciza: onorariul orar, onorariul fx (forfetar), 
        onorariul de succes, onorariul format prin combinarea unuia sau a mai multor tipuri de onorarii 
        anterior menţionate, moneda de plată, modalităţi de plată etc.
          </Text>
          <Text style={styles.text}>ARTICOLUL 3 - Cheltuieli
3.1. Cheltuieli: Cele mai sus mentionate
ARTICOLUL 4 - Clauze speciale
4.1. Prezentul contract este titlu executoriu cu privire la sumele datorate de către client cu titlu de 
onorariu şi cheltuieli aferente.
4.2. Raporturile dintre părţi nu pot f dovedite decât cu prezentul contract şi/sau în condiţiile 
prevăzute de Statutul profesiei de avocat.
4.3. Faţă de terţi dovada prezentului contract se face prin împuternicire avocaţială. Prezentul 
contract nu poate f adus la cunoştinţa terţilor decât cu acordul expres al părţilor.
4.4. Clientul atestă exactitatea şi sinceritatea informaţiilor pe care le furnizează avocatului şi îşi 
exprimă acordul ca demersurile făcute de avocat să fe conform informaţiilor pe care le-a 
furnizat.
4.5. Executarea obligaţiilor asumate de forma de exercitare a profesiei se realizează de către 
avocaţii care îşi exercită profesia în cadrul acestuia.
4.6. Neplata onorariului în cuantumul şi la termenele fxate potrivit art. 2 din contract, precum şi 
neachitarea contravalorii cheltuielilor efectuate potrivit art. 3 din contract dau dreptul avocatului 
să procedeze la rezilierea de plin drept a prezentului contract fără nici o altă formalitate şi fără 
nici o altă procedură judiciară sau extrajudiciară. Prezentul pact comisoriu de gradul IV îşi 
produce efectele de la data scadenţei obligaţiei neexecutate.
4.7. Toate litigiile privitoare la naşterea, modifcarea, stingerea, interpretarea şi executarea 
prezentului contract pot f supuse regulilor de arbitraj şi regulilor de procedură prevăzute de lege 
şi de statutul profesiei de avocat.
ARTICOLUL 5 - Locul şi data încheierii. Înregistrarea contractului
Încheiat la (locul încheierii, modalitatea încheierii în cazul încheierii la distanţă) via e-mail, astăzi 
14/07/2024 , în două exemplare, ambele părţi atestând că exemplarul original a fost înmânat/
comunicat clientului sau reprezentantului acestuia iar un exemplar a fost păstrat de către forma 
de exercitare a profesiei. Contractul a fost înregistrat sub nr. ........................ din 14/07/2024 în 
registrul de evidenţă a contractelor de asistenţă juridică.
 CLIENT/REPREZENTANT,
prezentului contract prin avocat, Gabi Gavril
Semnătura, Semnătura,</Text>

          <View style={styles.signatures}>
          <View style={styles.signatureBlock}>
           
            <Text style={styles.signatureText}>
            (FORMA DE EXERCITARE A PROFESIEI) </Text>
            <Text style={styles.signatureText}>Atest data, conţinutul actului şi identitatea semnatarului</Text>
            <Text style={styles.signatureText}>
            Semnătura,</Text> 
            {beneficiarSignature && (
              <Image src={beneficiarSignature} style={styles.signatureImage} />
            )}
          </View>
          <View style={styles.signatureBlock}>
            
            <Text style={styles.signatureText}>
            CLIENT/REPREZENTANT,</Text>
            <Text style={styles.signatureText}>
            Gabi Gavril</Text>
            <Text style={styles.signatureText}>
            Semnătura,</Text>
            {beneficiarSignature && (
              <Image src={beneficiarSignature} style={styles.signatureImage} />
            )}
          </View>
        </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Informare protecția datelor</Text>
          <Text style={styles.text}>
          
Înainte de a începerea raporturilor contractuale cu noi este necesar să luați la cunoștință și să înțelegeți 
deplin următoarea informare privind protecția datelor cu caracter personal destinată clienților PETRE 
ROȘU ȘI ASOCIAȚII – SOCIETATE CIVILĂ DE AVOCAȚI.
1. Respectăm dispozițiile legale privitoare la protecția datelor cu caracter personal și punem în aplicare 
măsuri tehnice și organizatorice de protejare a tuturor operațiunilor privitoare în mod direct sau indirect la 
datele cu caracter personal, care previn prelucrările neautorizate sau ilegale, precum și pierderile sau 
distrugerile accidentale sau ilegale.
2. Prelucrăm numele, prenumele și domiciliul dumneavoastră cuprinse în contractul încheiat cu noi, 
pentru:
– executarea contractului încheiat cu dumneavoastră, inclusiv realizarea activităților efectuate de noi în 
interesul dumneavoastră în virtutea contractului dintre noi;
– îndeplinirea obligațiilor fscale legate de plata onorariului;
– redactarea înscrisurilor în numele dumneavoastră, potrivit mandatului încredințat;
– înaintarea către instanțe și alte instituții, autorități, organizații și persoane fzice, din România și din alte 
țări, în calitate de reprezentant convențional sau apărător, a cererilor și altor înscrisuri necesare executării 
mandatului încredințat;
– atestarea identităţii părţilor, a conţinutului şi a datei actelor prezentate;
– îndeplinirea obligațiilor instituite de lege în sarcina avocaților;
– îndeplinirea obligației noastre profesionale de ținere a evidenței contractelor încheiate de forma de 
exercitate a profesiei de avocat.
Aceste date vor f păstrate pe durata ținerii evidențelor profesionale avocațiale.
3. Prelucrăm numărul dumneavoastră de telefon și adresa dumneavoastră de e-mail, pentru:
– comunicarea cu dumneavoastră.
Aceste date vor f păstrate pe durata ținerii evidențelor profesionale avocațiale.
4. Prelucrăm datele cuprinse în solicitările și informările dumneavoastră către noi și datele prepușilor și 
terților cuprinse în înscrisurile pe care ni le aduceți la cunoștință, pentru:
– realizarea interesului dumneavoastră legitim de a benefcia de asistență juridică și reprezentare 
avocațială;
– realizarea interesului public de realizare a serviciilor avocațiale și scopurilor legitime ale avocatului.
Aceste date vor f păstrate pe durata executării contractului dintre dumneavoastră și avocat.
5. Nu utilizăm datele cu caracter personal pentru prelucrare automatizată și nici pentru realizarea de 
profle. Nu luăm niciodată decizii automate cu privire la dumneavoastră. Utilizăm mijloace tehnice pentru 
stocarea datelor în condiții de securitate. Nu prelucrăm date în scopuri secundare incompatibile cu 
scopurile pentru care le-am colectat.
6. Respectăm și asigurăm în mod strict secretul profesional. Nu dezvăluim datele decât pentru realizarea 
intereselor dumneavoastră și îndeplinirea obligațiilor legale. Confdențialitatea este pentru noi nu doar o 
obligație profesională a avocatului, ci și o valoare esențială.
7. Revizuim în fecare an datele colectate, analizând în ce măsură păstrarea lor este necesară scopurilor 
menționate, intereselor dumneavoastră legitime sau îndeplinirii obligațiilor legale de către avocat. Datele 
care nu mai sunt necesare sunt șterse.
8. Benefciați de dreptul de acces, intervenție, rectifcare și portare cu privire la datele pe care ni le 
furnizați, de dreptul de a solicita limitarea prelucrării realizate de noi și chiar de a solicita ștergerea 
datelor. Menționăm că intervenția asupra datelor pe care ni le-ați furnizat poate f de natură să împiedice 
executarea contractului încheiat între dumneavoastră și avocat. În acest caz avocatul este exonerat de 
răspundere. Vă rugăm să ne aduceți la cunoștință solicitările dumneavoastră, în orice modalitate care 
este asigurătoare cu privire la identitatea solicitantului și îndreptățirea lui. Vă vom răspunde neîntârziat. 
Dacă sunteți nemulțumit, aveți posibilitatea să vă adresați ANSPDCP.
Pentru a ne scrie cu privire protecția datelor cu caracter personal, vă rugăm să ne scrieți la adresa 
București, Str Stanilesti Nr 56 Sector 1.
Subsemnatul/a Gabi Gavril am luat la cunoștință de prezenta informare, astăzi 14/07/2024.
Semnătura</Text>

         
          <View style={styles.signatureBlock}>
            {beneficiarSignature && (
              <Image src={beneficiarSignature} style={styles.signatureImage} />
            )}
        
        </View>
        </View>
      </Page>
    </Document>
  );
};

export default ContractPDF;
