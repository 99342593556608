import React from 'react';

const ToggleButton = ({ stage }) => {
 

  return (
    <nav aria-label="Progress" className="hidden md:block"><ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
      <li className="relative md:flex md:flex-1">
        <div className="group flex w-full items-center">
          <span className="flex items-center px-6 py-2 text-sm font-medium">
          <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-indigo-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-white"><path fillRule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path></svg></span>
          <span className="ml-4 text-sm font-medium text-gray-900">Document</span></span></div><div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path></svg>
              </div></li>
              {stage === 1 && <li className="relative md:flex md:flex-1">
                <div className="flex items-center px-6 py-2 text-sm font-medium" aria-current="step">
                <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-800">
                <span className="text-xs text-indigo-800">02</span></span><span className="ml-4 text-sm font-medium text-indigo-800">Date</span></div>
                <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path>
                  </svg>
                </div>
              </li>}
              {stage > 1 && <li className="relative md:flex md:flex-1">
                <div className="group flex w-full items-center">
          <span className="flex items-center px-6 py-2 text-sm font-medium">
          <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-indigo-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-white"><path fillRule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path></svg></span>
          <span className="ml-4 text-sm font-medium text-gray-900">Date</span></span></div><div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path></svg>
              </div>
              </li>}
                      {stage <= 1 && <li className="relative md:flex md:flex-1"><div className="group flex items-center"><span className="flex items-center py-2 text-sm font-medium px-6">
                        <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300"><span className="text-gray-500 text-xs">03</span></span>
                        <span className="ml-4 text-sm font-medium text-gray-500">Plată</span></span></div><div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true"><svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none"><path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path></svg></div></li>}
                        {stage === 2 && <li className="relative md:flex md:flex-1">
                <div className="flex items-center px-6 py-2 text-sm font-medium" aria-current="step">
                <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-800">
                <span className="text-xs text-indigo-800">03</span></span><span className="ml-4 text-sm font-medium text-indigo-800">Plată</span></div>
                <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path>
                  </svg>
                </div>
              </li>}
              {stage > 2 && <li className="relative md:flex md:flex-1">
                <div className="group flex w-full items-center">
          <span className="flex items-center px-6 py-2 text-sm font-medium">
          <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-indigo-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-white"><path fillRule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path></svg></span>
          <span className="ml-4 text-sm font-medium text-gray-900">Plată</span></span></div><div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path></svg>
              </div>
              </li>}
              {stage <= 2 && <li className="relative md:flex md:flex-1"><div className="group flex items-center"><span className="flex items-center py-2 text-sm font-medium px-6">
                        <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300"><span className="text-gray-500 text-xs">04</span></span>
                        <span className="ml-4 text-sm font-medium text-gray-500">Facturare</span></span></div><div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true"><svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none"><path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path></svg></div></li>}
                        {stage === 3 && <li className="relative md:flex md:flex-1">
                <div className="flex items-center px-6 py-2 text-sm font-medium" aria-current="step">
                <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-800">
                <span className="text-xs text-indigo-800">04</span></span><span className="ml-4 text-sm font-medium text-indigo-800">Facturare</span></div>
                <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path>
                  </svg>
                </div>
              </li>}
              {stage > 3 && <li className="relative md:flex md:flex-1">
                <div className="group flex w-full items-center">
          <span className="flex items-center px-6 py-2 text-sm font-medium">
          <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-indigo-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-white"><path fillRule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path></svg></span>
          <span className="ml-4 text-sm font-medium text-gray-900">Facturare</span></span></div><div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path></svg>
              </div>
              </li>}
              { stage <= 3 && <li className="relative md:flex md:flex-1"><div className="group flex items-center"><span className="flex items-center py-2 text-sm font-medium px-6"><span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300"><span className="text-gray-500 text-xs">05</span></span>
              <span className="ml-4 text-sm font-medium text-gray-500">Finalizare</span></span></div></li>}
                        {stage === 4 && <li className="relative md:flex md:flex-1">
                <div className="flex items-center px-6 py-2 text-sm font-medium" aria-current="step">
                <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-800">
                <span className="text-xs text-indigo-800">05</span></span><span className="ml-4 text-sm font-medium text-indigo-800">Finalizare</span></div>
                <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path>
                  </svg>
                </div>
              </li>}
              {stage > 4 && <li className="relative md:flex md:flex-1">
                <div className="group flex w-full items-center">
          <span className="flex items-center px-6 py-2 text-sm font-medium">
          <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-indigo-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-white"><path fillRule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path></svg></span>
          <span className="ml-4 text-sm font-medium text-gray-900">Finalizare</span></span></div><div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"></path></svg>
              </div>
              </li>}
    </ol></nav>
        
  );
};

export default ToggleButton;
