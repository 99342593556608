import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ClientForm, { ClientFooter } from './components/ClientForm';
import ProgressHistogram from './components/ProgressHistogram';
import logo from './assets/logo192.png';
import "./App.css";
import LandingPage from './page/LandingPage';
// import sendDataToSheet from './Sheets';
import AdminPage from "./page/AdminPage";
import OptionsPage, { OptionsFooter } from './page/OptionsPage';
import Signature, {SignatureFooter} from './components/Signature';
import InvoiceForm, { InvoiceFooter } from './page/InvoicePage';
import PaymentForm from './page/PaymentPage'
import Return from './page/ReturnPage';
import TermeniPage from './page/TermeniPage';
import AboutPage from './page/AboutPage';
import PricePage from './page/PreturiPage';
import ContactPage from './page/Contact';
import FooterBase from './components/FooterBase';
import CookiesPage from './page/CookiesPage';
import GDPRPage from './page/GDPRPage';

function App() {
  const currentTime = new Date().toLocaleString();
  const [idProcesareClient,setIdProcesareClient] = useState("null");
  const [progress, setProgress] = useState(0);
  const [submitedData, setSubmitedData] = useState({});
  const [options, setOptions] = useState({electronic: true, tara_utilizare: false, 'apostila haga': false,  time: currentTime});
  const [biling, setBilling] = useState({});
  const [error, raiseError] = useState(false);
  const [images, setImages] = useState({ document: null, owner: null });
  const [contract, setContract] = useState();

  return (
    <Router>
      <div className="App min-h-screen flex flex-col justify-between items-center w-full">
        <header className="sticky top-0 z-50 transition duration-500 w-full shadow-md shadow-slate-900/5 dark:shadow-none bg-white">
          <div className="max-w-2xl mx-auto lg:px-8 md:max-w-7xl xl:px-0 flex flex-wrap items-center justify-between px-4 py-5">
          <div className="relative flex flex-grow basis-0 items-center">
            <a aria-label="Home page" href="/">
            <img src={logo} alt="Logo" className="h-8 w-8" />
            </a>
            <a aria-label="Home page" href="/">
            <h1 className="text-2xl font-semibold md:text-4xl">Cazio</h1></a>
            </div>
          
          
          <Routes>
            <Route path="/cazier/:id" element={<ProgressHistogram stage={1}/>}/>
            <Route path="/optiuni/:id" element={<ProgressHistogram stage={2}/>}/>
            <Route path="/"  element={
              <nav>
              <ul className="flex space-x-4">
                <li><Link to="/about" className="hover:text-blue-300 text-base md:text-lg">Despre Noi</Link></li>
                <li><Link to="/contact" className="hover:text-blue-300 text-base md:text-lg">Contact</Link></li>
                <li><Link to="/pricing" className="hover:text-blue-300 text-base md:text-lg">Produse</Link></li>
              </ul>
            </nav>}/>
          </Routes>
          </div>
          </header>
          <Routes>
            <Route path="/about" element={<AboutPage/>} />
            <Route path="/contact" element={<ContactPage/>} />
            <Route path="/pricing" element={<PricePage/>} />
            <Route path="/politica-de-confidentialitate" element={<TermeniPage/>} />
            <Route path="/admin-center" element={<AdminPage/>}/>
            <Route path="/politica-cookies" element={<CookiesPage/>}/>
            <Route path="/gdpr" element={<GDPRPage/>}/>
            <Route path='/cazier'>
              <Route path=":id" element={<ClientForm setProgress={setProgress} setSubmitedData={setSubmitedData} submitted={error} raiseError={raiseError}/>}/>
            </Route>
            <Route path='/optiuni'>
              <Route path=":id" element={<OptionsPage setProgress={setProgress} options={options} setOptions={setOptions} images={images} setImages={setImages} idProcesareClient={idProcesareClient} />}/>
            </Route>
            <Route path='/signature'>
              <Route path=":id" element={<Signature idProcesareClient={idProcesareClient} client={submitedData} setPDFBlob={setContract}/>}/>
            </Route>
            <Route path='/billing'>
              <Route path=":id" element={<InvoiceForm setProgress={setProgress} setSubmitedData={setBilling} idProcesareClient={idProcesareClient} raiseError={raiseError}/>}/>
            </Route>
            <Route path='/finalizare'>
              <Route path=":id" element={<PaymentForm formData={{invoice: biling, client: submitedData, product: options, idProcesareClient: idProcesareClient}}/>}/>
            </Route>
            <Route path='/return'>
              <Route path=":id" element={<Return />}/>
            </Route>
            <Route path="/"  element={<LandingPage setIdProcesareClient={setIdProcesareClient}/>} />
          </Routes>
        <Routes>
            <Route path="/cazier/:id" element={<ClientFooter progress={progress} idProcesareClient={idProcesareClient} formData={submitedData} raiseError={raiseError}/>}/>
            <Route path="/optiuni/:id" element={<OptionsFooter progress={progress} idProcesareClient={idProcesareClient} options={options} images={images} raiseError={raiseError}/>}/>
            <Route path="/signature/:id" element={<SignatureFooter contract={contract} idProcesareClient={idProcesareClient}/>}/>
            <Route path="/billing/:id" element={<InvoiceFooter progress={progress} idProcesareClient={idProcesareClient} formData={biling} images={images} raiseError={raiseError}/>}/>
            {/* <Route path="/finalizare/:id" element={<InvoiceFooter progress={progress} idProcesareClient={idProcesareClient} options={options} images={images} raiseError={raiseError}/>}/> */}
            <Route path="/"  element={<FooterBase/>} />
            <Route path="/about"  element={<FooterBase/>} />
            <Route path="/contact"  element={<FooterBase/>} />
            <Route path="/pricing"  element={<FooterBase/>} />
            <Route path="/gdpr"  element={<FooterBase/>} />
            <Route path="/politica-cookies"  element={<FooterBase/>} />
            <Route path="/politica-de-confidentialitate"  element={<FooterBase/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
