import React, { useEffect, useState } from 'react';
import { API_URL } from "../config";
import Modal from '../components/Modal'; // Assuming Modal is in the same directory
import axios from 'axios';

const AdminPage = () => {
  const [clients, setClients] = useState([]);
  const [logIn, setLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (logIn) {
      fetch(API_URL+'/api/adminGetAllClients', {
        method: 'GET',
        headers: {
          'X-Password': password 
        }})
      .then(res => res.json())
      .then(data => {
        // Convert the dictionary to an array and sort it
        const clientsArray = Object.keys(data).map(key => ({
          clientKey: key,
          ...data[key],
          time: data[key].time || new Date().toLocaleString() // Ensure each entry has a time value
        }));
        clientsArray.sort((a, b) => new Date(a.time) - new Date(b.time));
        setClients(clientsArray);
      });
    }
  }, [logIn]);

  const handleLogin = () => {
    setLogin(true);
  };

  const handleOpenModal = (clientKey) => {
    setSelectedClient(clientKey);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedClient(null);
  };

  const handleUpdateStatus = (clientKey, status) => {
    axios.put(API_URL+'/api/updateClient', {
      formData: {orderStatus: status},
      idProcesareClient: clientKey,
    })
    .then(response => {
      if (!response.data.error) {
        setModalOpen(false);
        fetch(API_URL+'/api/adminGetAllClients', {
          method: 'GET',
          headers: {
            'X-Password': password 
          }})
        .then(res => res.json())
        .then(data => {
          const clientsArray = Object.keys(data).map(key => ({
            clientKey: key,
            ...data[key],
            time: data[key].time || new Date().toLocaleString() // Ensure each entry has a time value
          }));
          clientsArray.sort((a, b) => new Date(a.time) - new Date(b.time));
          setClients(clientsArray);
        });
      } else {
        console.error('Error:', response.data.error);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  if (!logIn) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="w-full max-w-xs">
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Admin Center
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center" style={{justifyContent: "flex-end"}}>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    );      
  }

  return (
    <div className='flex flex-col'>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Client Key
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Data
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Legal Entity
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Payment
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Order Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {clients.map((client) => { 
            const orderStatus = client.orderStatus;
  
            const orderStatusColor = {
              'Preluat': 'bg-blue-500',
              'Processing': 'bg-green-500',
              'Shipped': 'bg-yellow-500',
              'Delivered': 'bg-purple-500'
            }[orderStatus] || 'bg-gray-300';  
            return (
            <tr key={client.clientKey}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{client.clientKey}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{client.time}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <button
                  className={`px-4 py-2 rounded ${
                    client.legalEntity ? 'bg-violet-500 text-white' : 'bg-orange-300 text-gray-700'
                  }`}
                >
                  {client.legalEntity ? 'Legal Entity' : 'Individual'}
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <button
                  className={`px-4 py-2 rounded ${
                    client.paid ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'
                  }`}
                >
                  {client.paid ? 'Paid' : 'Not Paid'}
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <button
                  className={`px-4 py-2 rounded ${orderStatusColor} text-white`}
                  onClick={() => handleOpenModal(client.clientKey)}
                >
                  {client.orderStatus || 'Nepreluat'}
                </button>
              </td>
            </tr>
          )})}
        </tbody>
      </table>

      {/* Modal Component */}
      <Modal
        client={clients.find(client => client.clientKey === selectedClient)}
        idProcesareClient={selectedClient}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onUpdateStatus={handleUpdateStatus}
        password={password}
      />
    </div>
  );  
};

export default AdminPage;
