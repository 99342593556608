import React, {useCallback, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import Revizuire from '../components/Revizuire';
import ReviewFinal from '../components/ReviewFinal';
import PaymentBox from '../components/PaymentBox';
import { API_URL } from '../config';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe("pk_test_51HzlLyJyIWhDtR3Sb0DJBC29L4RXJrTUXrX26rXh68HMH8GIStrqXcY6hnZLZi8219NAm4LbMuT2g9RnUSfxmwPI00ltbCGvob");

const prices = {
  "traducere legalizata": 0,
  "apostila haga": 158,
  "tara_haga": 0,
  "urgent": 84,
  "electronic": 0,
  "tara_utilizare": 252,
  "limba_traducere": 0,
  "expert": 49,
  'livrare': 0,
  'time': 0,
};

const product_id = {
210: "price_1PizM3JyIWhDtR3SKpqvcC9y",
1003:"price_1PizLYJyIWhDtR3Su6QwBtD2",
845:"price_1PizLXJyIWhDtR3Sgh8hizZu",
954:"price_1PizLWJyIWhDtR3Sn88bkwt1",
753:"price_1PizLWJyIWhDtR3SGkEYROyP",
919:"price_1PizLVJyIWhDtR3SUuiWLPwd",
593:"price_1PizLUJyIWhDtR3SKBJCorSX",
796:"price_1PizLUJyIWhDtR3ScfzQyjIF",
595:"price_1PizLTJyIWhDtR3S3xNIanND",
761:"price_1PizLSJyIWhDtR3S9fZUqQpx",
704:"price_1PizLSJyIWhDtR3SyROeYBfN",
870:"price_1PizLRJyIWhDtR3ShkoFVBOb",
669:"price_1PizLQJyIWhDtR3Sa1Bp7ayQ",
544:"price_1PizLQJyIWhDtR3SM5nEbOtA",
343:"price_1PizLPJyIWhDtR3Sm6XZkKLW",
509:"price_1PizLOJyIWhDtR3Sgfom1mlC",
546:"price_1PizLOJyIWhDtR3SR4TJvxsg",
712:"price_1PizLNJyIWhDtR3S1kdSlZgM",
511:"price_1PizLMJyIWhDtR3SsQhc53Td",
620:"price_1PizLMJyIWhDtR3Swu66M3Eg",
294:"price_1PizLLJyIWhDtR3SwmAFHaAI",
460:"price_1PizLKJyIWhDtR3SJSfOB6SX",
259:"price_1PizLKJyIWhDtR3SSJ3KjBD8",
462:"price_1PizLJJyIWhDtR3SknLUJQii",
}

const computeProduct = (product) => {
  let price = 210;
  console.log(product);
  Object.keys(product).forEach(key => {
    if (product[key]) {
      price += prices[key];
    }
    if (key === 'livrare' && product[key]['delivery_country']!=='Romania'){
      price += 250;
    }
  });
  console.log(price, product_id[price]);
  return { prodId: product_id[price], priceProd:price};
}


const CheckoutForm = ({ productId, idProcesareClient }) => {
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch(API_URL + "/create-checkout-session", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ priceId: productId, idProcesareClient })
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, [productId, idProcesareClient]); 

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}


function PaymentForm({formData}) {
  const {invoice, client, product, idProcesareClient} = formData;
  const [productId, setProductId] = useState(null);
  const [price, setPrice] = useState(0);

  useEffect(()=>{
    let {prodId, priceProd} = computeProduct(product)
    setProductId(prodId);
    setPrice(priceProd);
    console.log(prodId, priceProd);
  },[product]);
  
  return (
    <div className="mx-auto max-w-5xl py-10 px-4 sm:py-16 sm:px-6 lg:px-8 w-full grow">
      <h1 className="text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 mb-3">Plătește acum</h1>
    <p className="mb-7 text-base text-gray-600 flex justify-between items-center">Aproape ai terminat! Documentele tale sunt la doar un pas distanță.</p>
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 max-w-5xl mb-6 min-h-[500px]">
    {/* <PaymentBox></PaymentBox> */}
    {productId && idProcesareClient && <CheckoutForm productId={productId} idProcesareClient={idProcesareClient}/>}
    <Revizuire formData={formData} total={price}></Revizuire>
    </div>
    <ReviewFinal/>
    </div>
  );
}

export default PaymentForm;
