import React from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import ContractPDF from './GenerateContract';

const DownloadPDFButton = ({ userData, prestatorSignature, beneficiarSignature, onBlobReady }) => {
  return (
    <BlobProvider
      document={
        <ContractPDF
          userData={userData}
          prestatorSignature={prestatorSignature}
          beneficiarSignature={beneficiarSignature}
        />
      }>
      {({ blob, url, loading, error }) => {
        if (error) {
          return <div>Something went wrong while generating the PDF</div>;
        }

        if (loading) {
          return <button type="button" className="flex justify-center items-center py-2 px-4 rounded-full shadow-sm text-md border focus:outline-none focus:ring-2 focus:ring-offset-2 border-indigo-800 hover:bg-indigo-800 text-indigo-800 hover:text-white disabled:bg-gray-400">
          Se genereaza contractul
        </button>;
        }

        // Trigger the callback when the blob is ready
        if (blob && onBlobReady) {
          onBlobReady(blob);
        }

        return (
          <a href={url} download="Contract_Prestari_Servicii.pdf">
            <button type="button" className="flex justify-center items-center py-2 px-4 rounded-full shadow-sm text-md border focus:outline-none focus:ring-2 focus:ring-offset-2 border-indigo-800 hover:bg-indigo-800 text-indigo-800 hover:text-white disabled:bg-gray-400">
                                                Descarca Contract
            </button>
          </a>
        );
      }}
    </BlobProvider>
  );
};

export default DownloadPDFButton;
