import operator from "../assets/operator.webp"
import hourglass from "../assets/hourglass.webp"
import team from "../assets/team.webp"
import phone from "../assets/phone.webp"

const AboutPage = () => {

    return (<>
        {/* Hello world */}
        <div className="w-full grow">
          <section className="bg-indigo-800">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-32 lg:px-12">
              <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
                Despre cazieronline.ro
              </h1>
              <p className="mb-8 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48">
                Bun venit la cazieronline.ro - locul unde simplificăm procesul de obținere
                a documentelor oficiale pentru clienții noștri!
              </p>
              <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                <a
                  className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg focus:ring-4 focus:ring-white border border-white hover:bg-white hover:text-indigo-800"
                  href="#despre-noi-features"
                >
                  Află mai multe
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                    className="ml-2 -mr-1 w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </section>
          <section className="bg-white dark:bg-gray-900" id="despre-noi-features">
            <div className="py-8 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-20 sm:py-16 lg:px-6">
              <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
                <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                    Misiunea noastră
                  </h2>
                  <p className="mb-8 font-light lg:text-xl">
                    La cazieronline.ro, ne-am asumat misiunea de a oferi clienților noștri
                    un serviciu rapid, sigur și ușor de utilizat pentru obținerea
                    diverselor documente oficiale. Eliberarea de documente precum
                    cazierul judiciar, extrasul de carte funciară, certificatul
                    constatator și multe altele poate fi uneori o sarcină complicată
                    și consumatoare de timp. De aceea, ne-am dedicat să creăm o
                    platformă online intuitivă, care să faciliteze procesul și să
                    asigure că clienții noștri obțin rapid documentele de care au
                    nevoie.
                  </p>
                </div>
                <img
                  alt="Misiunea noastră"
                  loading="lazy"
                  width={1217}
                  height={1058}
                  decoding="async"
                  data-nimg={1}
                  className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
                  src={hourglass}
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
                <img
                  alt="Ce ne diferențiază"
                  loading="lazy"
                  width={1217}
                  height={1058}
                  decoding="async"
                  data-nimg={1}
                  className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
                 src={team}
                  style={{ color: "transparent" }}
                />
                <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                    Ce ne diferențiază
                  </h2>
                  <p className="mb-8 font-light lg:text-xl">
                    Echipa noastră este formată din profesioniști cu experiență,
                    dedicați și pasionați de a oferi cea mai bună experiență
                    clienților noștri. Suntem orientați către soluții, iar clientul
                    este întotdeauna în centrul atenției noastre. Înțelegem importanța
                    datelor personale și a confidențialității, motiv pentru care
                    respectăm întotdeauna cele mai înalte standarde de securitate și
                    protecție a datelor.
                  </p>
                </div>
              </div>
              <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
                <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                    Cum funcționează
                  </h2>
                  <p className="mb-8 font-light lg:text-xl">
                    Procesul de obținere a documentelor de pe cazieronline.ro este simplu
                    și eficient. Tot ce trebuie să faceți este să selectați documentul
                    de care aveți nevoie, să completați informațiile necesare și să
                    finalizați comanda. Echipa noastră va prelucra cererea și veți
                    primi documentul solicitat în cel mai scurt timp posibil.
                  </p>
                </div>
                <img
                  alt="Cum funcționează"
                  loading="lazy"
                  width={1217}
                  height={1058}
                  decoding="async"
                  data-nimg={1}
                  className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
                  src={phone}
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
                <img
                  alt="Transparență și Suport"
                  loading="lazy"
                  width={1217}
                  height={1058}
                  decoding="async"
                  data-nimg={1}
                  className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
                  src={operator}
                  style={{ color: "transparent" }}
                />
                <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                    Transparență și Suport
                  </h2>
                  <p className="mb-8 font-light lg:text-xl">
                    Suntem dedicați transparenței și suntem întotdeauna disponibili
                    pentru a oferi asistență și răspunsuri la întrebările
                    dumneavoastră. Dacă aveți nelămuriri sau aveți nevoie de ajutor,
                    echipa noastră de suport este aici pentru dumneavoastră.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
      );
};

export default AboutPage;