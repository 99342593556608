import React from 'react';

const DeliveryReview = ({ deliveryData }) => {
    if (!deliveryData) return <></>; // If no delivery data is provided, render nothing.

    return (
        <div className="flex flex-col rounded-xl p-4 border shadow justify-between border-indigo-300 bg-indigo-100 mt-5">
            <h2 className="font-semibold text-2xl mb-2 border-b-2 border-indigo-300">Revizuire Livrare</h2>
            <div className="w-full grid md:grid-cols-2 gap-4">
                <div>
                    <p className="text-base leading-5 font-bold text-gray-900">Adresă:</p>
                    <p className="text-sm font-medium text-gray-500">{deliveryData.delivery_address}</p>
                </div>
                <div>
                    <p className="text-base leading-5 font-bold text-gray-900">Oraș:</p>
                    <p className="text-sm font-medium text-gray-500">{deliveryData.delivery_city}</p>
                </div>
                <div>
                    <p className="text-base leading-5 font-bold text-gray-900">Țară:</p>
                    <p className="text-sm font-medium text-gray-500">{deliveryData.delivery_country}</p>
                </div>
                <div>
                    <p className="text-base leading-5 font-bold text-gray-900">Cod Poștal:</p>
                    <p className="text-sm font-medium text-gray-500">{deliveryData.delivery_zipcode}</p>
                </div>
                <div>
                    <p className="text-base leading-5 font-bold text-gray-900">Telefon:</p>
                    <p className="text-sm font-medium text-gray-500">{deliveryData.delivery_telephone}</p>
                </div>
                {deliveryData.delivery_other_details && (
                    <div>
                        <p className="text-base leading-5 font-bold text-gray-900">Detalii Suplimentare:</p>
                        <p className="text-sm font-medium text-gray-500">{deliveryData.delivery_other_details}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DeliveryReview;
