import React from 'react';

const CustomToggleButton = ({
  leftSelected,
  handleFormTypeChange,
  leftText,
  rightText,
  vertical = false
}) => {
  // Base classes for button appearance
  const buttonBaseClass = "border font-semibold cursor-pointer flex justify-center items-center text-sm lg:text-md p-2 md:px-4 lg:px-6";
  // Class for the left button when selected
  const buttonLeftSelectedClass = `${buttonBaseClass} bg-indigo-800 text-white rounded-t-xl sm:rounded-tr-none sm:rounded-l-xl`;
  // Class for the left button when not selected
  const buttonLeftNotSelectedClass = `${buttonBaseClass} text-gray-900 hover:bg-sky-100 rounded-t-xl sm:rounded-tr-none sm:rounded-l-xl`;
  // Class for the right button when selected
  const buttonRightSelectedClass = `${buttonBaseClass} bg-indigo-800 text-white rounded-b-xl sm:rounded-bl-none sm:rounded-r-xl`;
  // Class for the right button when not selected
  const buttonRightNotSelectedClass = `${buttonBaseClass} text-gray-900 hover:bg-sky-100 rounded-b-xl sm:rounded-bl-none sm:rounded-r-xl`;

  return (
    <div className="">
      <label
        htmlFor="client_type"
        className="block mb-2 text-gray-900 w-fit mx-auto font-semibold"
      >
        Tipul clientului
      </label>
      <div className="w-full sm:w-fit mx-auto grid grid-flow-row sm:grid-flow-col">
      <div
        className={leftSelected ? buttonLeftSelectedClass : buttonLeftNotSelectedClass}
        onClick={() => !leftSelected && handleFormTypeChange()}
      >
       
          {leftSelected ? (
             <div className="inline-flex w-4 h-4 border rounded-full items-center justify-center mr-2 border-white">
            <div className="w-2 h-2 bg-white rounded-full" />
            </div>
          ): <div className="inline-flex w-4 h-4 border rounded-full items-center justify-center mr-2 border-indigo-800" />}
        {leftText}
      </div>
      <div
        className={!leftSelected ? buttonRightSelectedClass : buttonRightNotSelectedClass}
        onClick={() => leftSelected && handleFormTypeChange()}
      >
        {!leftSelected ? (
             <div className="inline-flex w-4 h-4 border rounded-full items-center justify-center mr-2 border-white">
            <div className="w-2 h-2 bg-white rounded-full" />
            </div>
          ): <div className="inline-flex w-4 h-4 border rounded-full items-center justify-center mr-2 border-indigo-800" />}
        {rightText}
      </div>
    </div>
    </div>
  );
};

export default CustomToggleButton;
