import React, { useCallback, useEffect, useState } from 'react';

const MotivSolicitare = ({ formData, handleChange, formErrors }) => {
    return (
      <div className="relative handle-outside-click">
        <label
          htmlFor="request_reason"
          className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center"
        >
          Motivul solicitării (scris pe document)
        </label>
            <select name="motive"
                    value={formData.motive} 
                    onChange={handleChange} 
                    className={`mt-1 p-2 border ${formErrors.motive ? 'border-red-500' : 'border-gray-300'} w-full rounded bg-gray-100 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400`}
                    required>
<option value="">Alege motivul</option>
  <option value="A.D.R">A.D.R</option>
  <option value="A.N.A.F.">A.N.A.F.</option>
  <option value="A.P.I.A">A.P.I.A</option>
  <option value="A.R.R.">A.R.R.</option>
  <option value="ACCESARE FONDURI">ACCESARE FONDURI</option>
  <option value="ACHIZIȚIE ARMĂ/ARMĂ VÂNĂTOARE">
    ACHIZIȚIE ARMĂ/ARMĂ VÂNĂTOARE
  </option>
  <option value="ACHIZIȚII">ACHIZIȚII</option>
  <option value="ACTIVITATE TRANSPORT">ACTIVITATE TRANSPORT</option>
  <option value="ADMINISTRAȚIE">ADMINISTRAȚIE</option>
  <option value="ADMINISTRATOR">ADMINISTRATOR</option>
  <option value="ADOPȚIE">ADOPȚIE</option>
  <option value="ALEGERI">ALEGERI</option>
  <option value="ALTE MOTIVE">ALTE MOTIVE</option>
  <option value="AMBASADĂ">AMBASADĂ</option>
  <option value="AMBASADA S.U.A">AMBASADA S.U.A</option>
  <option value="ANGAJARE">ANGAJARE</option>
  <option value="ASISTENT MATERNAL">ASISTENT MATERNAL</option>
  <option value="ASOCIAT/ASOCIAȚIE">ASOCIAT/ASOCIAȚIE</option>
  <option value="ASOCIAŢIA VÂNĂTORILOR">ASOCIAŢIA VÂNĂTORILOR</option>
  <option value="ATESTAT/ATESTARE">ATESTAT/ATESTARE</option>
  <option value="AUTORITATEA NAȚIONALĂ PENTRU CETĂȚENIE">
    AUTORITATEA NAȚIONALĂ PENTRU CETĂȚENIE
  </option>
  <option value="AUTORITĂȚI">AUTORITĂȚI</option>
  <option value="AUTORIZAȚIE/AUTORIZARE">AUTORIZAȚIE/AUTORIZARE</option>
  <option value="AVIZ/AVIZARE">AVIZ/AVIZARE</option>
  <option value="BANCĂ">BANCĂ</option>
  <option value="CANDIDATURĂ ALEGERI">CANDIDATURĂ ALEGERI</option>
  <option value="CĂSĂTORIE">CĂSĂTORIE</option>
  <option value="CETĂȚEAN DE ONOARE">CETĂȚEAN DE ONOARE</option>
  <option value="CETĂȚENIE">CETĂȚENIE</option>
  <option value="COLEGIUL ASISTENȚILOR MEDICALI">
    COLEGIUL ASISTENȚILOR MEDICALI
  </option>
  <option value="COLEGIUL ASISTENȚILOR SOCIALI">
    COLEGIUL ASISTENȚILOR SOCIALI
  </option>
  <option value="COLEGIUL CONSILIERILOR JURIDICI">
    COLEGIUL CONSILIERILOR JURIDICI
  </option>
  <option value="COLEGIUL MEDICILOR">COLEGIUL MEDICILOR</option>
  <option value="COLEGIUL MEDICILOR DENTIȘTI">
    COLEGIUL MEDICILOR DENTIȘTI
  </option>
  <option value="COLEGIUL MEDICILOR VETERINARI">
    COLEGIUL MEDICILOR VETERINARI
  </option>
  <option value="COLEGIUL NAȚIONAL DE ADMINISTRAȚIE">
    COLEGIUL NAȚIONAL DE ADMINISTRAȚIE
  </option>
  <option value="COLEGIUL NAȚIONAL DE APĂRARE">
    COLEGIUL NAȚIONAL DE APĂRARE
  </option>
  <option value="COLEGIUL PSIHOLOGILOR">COLEGIUL PSIHOLOGILOR</option>
  <option value="COMISIE">COMISIE</option>
  <option value="CONCURS/EXAMEN">CONCURS/EXAMEN</option>
  <option value="CURSURI">CURSURI</option>
  <option value="D.G.A.S.M.B">D.G.A.S.M.B</option>
  <option value="D.G.A.S.P.C.">D.G.A.S.P.C.</option>
  <option value="DOBÂNDIREA UNEI CETĂȚENII STRĂINE">
    DOBÂNDIREA UNEI CETĂȚENII STRĂINE
  </option>
  <option value="DOSAR">DOSAR</option>
  <option value="DOSAR COMPENSAȚII CF. HG 167/2024">
    DOSAR COMPENSAȚII CF. HG 167/2024
  </option>
  <option value="DREPT DE MUNCĂ">DREPT DE MUNCĂ</option>
  <option value="DREPT DE ȘEDERE">DREPT DE ȘEDERE</option>
  <option value="EMIGRARE">EMIGRARE</option>
  <option value="EXAMEN AUTO/MOTO">EXAMEN AUTO/MOTO</option>
  <option value="FUNDAȚIE">FUNDAȚIE</option>
  <option value="GRAȚIERE">GRAȚIERE</option>
  <option value="I.S.C.T.R">I.S.C.T.R</option>
  <option value="IMIGRARE">IMIGRARE</option>
  <option value="ÎMPUTERNICIT FIRMĂ">ÎMPUTERNICIT FIRMĂ</option>
  <option value="ÎNCREDINȚARE MINOR">ÎNCREDINȚARE MINOR</option>
  <option value="ÎNDEPLINIREA CALITĂŢII DE MENTOR Conf. Lg.335/2013">
    ÎNDEPLINIREA CALITĂŢII DE MENTOR Conf. Lg.335/2013
  </option>
  <option value="ÎNFIINȚARE ASOCIAȚIE">ÎNFIINȚARE ASOCIAȚIE</option>
  <option value="ÎNFIINȚARE BIROU MEDIATOR">ÎNFIINȚARE BIROU MEDIATOR</option>
  <option value="ÎNFIINȚARE CABINET MEDICAL INDIVIDUAL">
    ÎNFIINȚARE CABINET MEDICAL INDIVIDUAL
  </option>
  <option value="ÎNFIINȚARE FEDERAȚIE">ÎNFIINȚARE FEDERAȚIE</option>
  <option value="ÎNFIINȚARE O.N.G.">ÎNFIINȚARE O.N.G.</option>
  <option value="ÎNFIINȚARE ORGANIZAȚIE">ÎNFIINȚARE ORGANIZAȚIE</option>
  <option value="ÎNFIINȚARE P.F.A.">ÎNFIINȚARE P.F.A.</option>
  <option value="ÎNFIINŢARE PARTID POLITIC">ÎNFIINŢARE PARTID POLITIC</option>
  <option value="ÎNFIINŢARE SOCIETATE COMERCIALĂ">
    ÎNFIINŢARE SOCIETATE COMERCIALĂ
  </option>
  <option value="ÎNREGISTRARE ÎN SCOP DE T.V.A">
    ÎNREGISTRARE ÎN SCOP DE T.V.A
  </option>
  <option value="ÎNSOŢITOR">ÎNSOŢITOR</option>
  <option value="ÎNSOȚITOR MINOR ÎN STRĂINĂTATE">
    ÎNSOȚITOR MINOR ÎN STRĂINĂTATE
  </option>
  <option value="INSPECTORATUL GENERAL PENTRU IMIGRĂRI">
    INSPECTORATUL GENERAL PENTRU IMIGRĂRI
  </option>
  <option value="INSTANȚA DE JUDECATĂ">INSTANȚA DE JUDECATĂ</option>
  <option value="INSTITUȚIE PUBLICĂ">INSTITUȚIE PUBLICĂ</option>
  <option value="INSTRUCTOR">INSTRUCTOR</option>
  <option value="ÎNTREPRINDERE INDIVIDUALĂ">ÎNTREPRINDERE INDIVIDUALĂ</option>
  <option value="LIBER PROFESIONIST/LIBERĂ PRACTICĂ">
    LIBER PROFESIONIST/LIBERĂ PRACTICĂ
  </option>
  <option value="LICENȚĂ">LICENȚĂ</option>
  <option value="LICENȚĂ TAXI">LICENȚĂ TAXI</option>
  <option value="LICENȚĂ TRANSPORT">LICENȚĂ TRANSPORT</option>
  <option value="LICITAȚIE">LICITAȚIE</option>
  <option value="LOCUL DE MUNCĂ/SERVICIU">LOCUL DE MUNCĂ/SERVICIU</option>
  <option value="MĂSURA 112">MĂSURA 112</option>
  <option value="MĂSURA 121">MĂSURA 121</option>
  <option value="MĂSURA 123">MĂSURA 123</option>
  <option value="MĂSURA 141">MĂSURA 141</option>
  <option value="MĂSURA 41.112">MĂSURA 41.112</option>
  <option value="MĂSURA 421 PRIN 121">MĂSURA 421 PRIN 121</option>
  <option value="MEMBRU ASOCIAȚIE">MEMBRU ASOCIAȚIE</option>
  <option value="MUNCĂ ÎN STRĂINĂTATE">MUNCĂ ÎN STRĂINĂTATE</option>
  <option value="O.A.M.G.M.A.M.R.">O.A.M.G.M.A.M.R.</option>
  <option value="O.N.R.C.">O.N.R.C.</option>
  <option value="O.P.C">O.P.C</option>
  <option value="O.R.N.I.S.S.">O.R.N.I.S.S.</option>
  <option value="OBȚINERE AUTORIZAȚIE TRADUCĂTOR ȘI INTERPRET">
    OBȚINERE AUTORIZAȚIE TRADUCĂTOR ȘI INTERPRET
  </option>
  <option value="OBȚINERE CETĂȚENIE ROMÂNĂ">OBȚINERE CETĂȚENIE ROMÂNĂ</option>
  <option value="OBȚINERE CETĂȚENIE S.U.A.">OBȚINERE CETĂȚENIE S.U.A.</option>
  <option value="OPERATOR DISPECER">OPERATOR DISPECER</option>
  <option value="OPERATOR TRANSPORT RUTIER">OPERATOR TRANSPORT RUTIER</option>
  <option value="ORDINUL ARHITECȚILOR">ORDINUL ARHITECȚILOR</option>
  <option value="ORDINUL ASISTENȚILOR MEDICALI">
    ORDINUL ASISTENȚILOR MEDICALI
  </option>
  <option value="ORDINUL BIOLOGILOR">ORDINUL BIOLOGILOR</option>
  <option value="ORDINUL CONSILIERILOR JURIDICI">
    ORDINUL CONSILIERILOR JURIDICI
  </option>
  <option value="ORDINUL FARMACIȘTILOR">ORDINUL FARMACIȘTILOR</option>
  <option value="ORDINUL MEDICILOR">ORDINUL MEDICILOR</option>
  <option value="ORDINUL PRACTICIENILOR DE MEDICINĂ COMPLEMENTARĂ">
    ORDINUL PRACTICIENILOR DE MEDICINĂ COMPLEMENTARĂ
  </option>
  <option value="ORDINUL TEHNICIENILOR DENTARI">
    ORDINUL TEHNICIENILOR DENTARI
  </option>
  <option value="ORGANIZAȚIE NON - GUVERNAMENTALĂ">
    ORGANIZAȚIE NON - GUVERNAMENTALĂ
  </option>
  <option value="ORGANIZAȚIE PROFESIONALĂ">ORGANIZAȚIE PROFESIONALĂ</option>
  <option value="PARCHET">PARCHET</option>
  <option value="PARLAMENTUL ROMÂNIEI">PARLAMENTUL ROMÂNIEI</option>
  <option value="PATRIARHIA ROMÂNĂ">PATRIARHIA ROMÂNĂ</option>
  <option value="PATRONAT">PATRONAT</option>
  <option value="PERMIS PORT ARMĂ">PERMIS PORT ARMĂ</option>
  <option value="PERMIS REZIDENȚĂ">PERMIS REZIDENȚĂ</option>
  <option value="PERMIS ȘEDERE">PERMIS ȘEDERE</option>
  <option value="PERMIS VÂNĂTOARE">PERMIS VÂNĂTOARE</option>
  <option value="PERSOANĂ DESEMNATĂ">PERSOANĂ DESEMNATĂ</option>
  <option value="PERSOANĂ FIZICĂ AUTORIZATĂ">PERSOANĂ FIZICĂ AUTORIZATĂ</option>
  <option value="PLĂTITOR ACCIZE">PLĂTITOR ACCIZE</option>
  <option value="PLĂTITOR T.V.A./T.V.A. U.E">PLĂTITOR T.V.A./T.V.A. U.E</option>
  <option value="PRACTICĂ">PRACTICĂ</option>
  <option value="PRELUNGIRE AUTORIZAȚIE">PRELUNGIRE AUTORIZAȚIE</option>
  <option value="PRELUNGIRE PERMIS PORT ARMĂ">
    PRELUNGIRE PERMIS PORT ARMĂ
  </option>
  <option value="PRELUNGIRE VIZĂ DE ȘEDERE/CARTE DE REZIDENȚĂ">
    PRELUNGIRE VIZĂ DE ȘEDERE/CARTE DE REZIDENȚĂ
  </option>
  <option value="PRESCHIMBARE PAȘAPORT">PRESCHIMBARE PAȘAPORT</option>
  <option value="PRESCHIMBARE PERMIS DE CONDUCERE STRĂIN">
    PRESCHIMBARE PERMIS DE CONDUCERE STRĂIN
  </option>
  <option value="PRESTĂRI SERVICII">PRESTĂRI SERVICII</option>
  <option value="PROCEDURĂ ADMINISTRATIVĂ/JUDICIARĂ">
    PROCEDURĂ ADMINISTRATIVĂ/JUDICIARĂ
  </option>
  <option value="PROGRAM">PROGRAM</option>
  <option value="PROGRAM A.F.N.">PROGRAM A.F.N.</option>
  <option value="PROGRAM F.E.A.D.R.">PROGRAM F.E.A.D.R.</option>
  <option value="PROGRAM H2B">PROGRAM H2B</option>
  <option value="PROGRAM L.E.A.D.R.">PROGRAM L.E.A.D.R.</option>
  <option value="PROGRAM P.O.C.A.">PROGRAM P.O.C.A.</option>
  <option value="PROGRAM POST C.C.E.">PROGRAM POST C.C.E.</option>
  <option value="PROGRAM S.A.P.A.R.D">PROGRAM S.A.P.A.R.D</option>
  <option value="PROGRAMUL CASA VERDE">PROGRAMUL CASA VERDE</option>
  <option value="PROIECT">PROIECT</option>
  <option value="PROIECTANT SISTEME ALARMARE/SECURITATE">
    PROIECTANT SISTEME ALARMARE/SECURITATE
  </option>
  <option value="REABILITARE">REABILITARE</option>
  <option value="REATESTARE/REAUTORIZARE">REATESTARE/REAUTORIZARE</option>
  <option value="REAVIZARE FIRMĂ DE PAZĂ">REAVIZARE FIRMĂ DE PAZĂ</option>
  <option value="RECUNOAȘTERE CĂSĂTORIE">RECUNOAȘTERE CĂSĂTORIE</option>
  <option value="RECUNOAȘTERE PROFESIONALĂ/STUDII">
    RECUNOAȘTERE PROFESIONALĂ/STUDII
  </option>
  <option value="RECUNOAȘTERE/ECHIVALARE DIPLOME">
    RECUNOAȘTERE/ECHIVALARE DIPLOME
  </option>
  <option value="REDOBÂNDIRE CETĂȚENIE">REDOBÂNDIRE CETĂȚENIE</option>
  <option value="REDOBÂNDIRE CETĂȚENIE ROMÂNĂ">
    REDOBÂNDIRE CETĂȚENIE ROMÂNĂ
  </option>
  <option value="REDOBÂNDIRE PERMIS AUTO">REDOBÂNDIRE PERMIS AUTO</option>
  <option value="REG. OPERATORILOR CU PROD. ACCIZABILE">
    REG. OPERATORILOR CU PROD. ACCIZABILE
  </option>
  <option value="REGISTRUL ARHITECTILOR">REGISTRUL ARHITECTILOR</option>
  <option value="REGISTRUL ASISTENȚILOR SOCIALI">
    REGISTRUL ASISTENȚILOR SOCIALI
  </option>
  <option value="REGISTRUL AUDITORILOR FINANCIARI">
    REGISTRUL AUDITORILOR FINANCIARI
  </option>
  <option value="REGISTRUL AUTO ROMÂN">REGISTRUL AUTO ROMÂN</option>
  <option value="REGISTRUL COMERȚULUI">REGISTRUL COMERȚULUI</option>
  <option value="REGISTRUL CONCILIATORILOR">REGISTRUL CONCILIATORILOR</option>
  <option value="REGISTRUL INTERMEDIARILOR DE CREDITE">
    REGISTRUL INTERMEDIARILOR DE CREDITE
  </option>
  <option value="REGISTRUL NAȚIONAL EVALUATORI RISC">
    REGISTRUL NAȚIONAL EVALUATORI RISC
  </option>
  <option value="REGISTRUL OPERATORILOR INTRACOMUNITARI">
    REGISTRUL OPERATORILOR INTRACOMUNITARI
  </option>
  <option value="REGISTRUL OPERATORILOR REGIONALI">
    REGISTRUL OPERATORILOR REGIONALI
  </option>
  <option value="REGISTRUL PLĂTITORILOR DE PRODUSE ACCIZABILE">
    REGISTRUL PLĂTITORILOR DE PRODUSE ACCIZABILE
  </option>
  <option value="REGISTRUL SPECIALIŞTILOR CONSTATARE DAUNE">
    REGISTRUL SPECIALIŞTILOR CONSTATARE DAUNE
  </option>
  <option value="REGISTRUL UNIC EUROPEAN">REGISTRUL UNIC EUROPEAN</option>
  <option value="REGISTRUL URBANIȘTILOR DIN ROMÂNIA">
    REGISTRUL URBANIȘTILOR DIN ROMÂNIA
  </option>
  <option value="REÎNNOIRE AUTORIZAȚIE DE IMPORTATOR">
    REÎNNOIRE AUTORIZAȚIE DE IMPORTATOR
  </option>
  <option value="REÎNNOIRE LICENȚIERE SISTEME ALARMARE">
    REÎNNOIRE LICENȚIERE SISTEME ALARMARE
  </option>
  <option value="REÎNTREGIREA FAMILIEI">REÎNTREGIREA FAMILIEI</option>
  <option value="RELICENȚIERE FIRMĂ">RELICENȚIERE FIRMĂ</option>
  <option value="RELICENȚIERE SOCIETATE">RELICENȚIERE SOCIETATE</option>
  <option value="RENUNȚARE CETĂȚENIE">RENUNȚARE CETĂȚENIE</option>
  <option value="RENUNȚARE LA CETĂȚENIA ROMÂNĂ">
    RENUNȚARE LA CETĂȚENIA ROMÂNĂ
  </option>
  <option value="REPATRIERE">REPATRIERE</option>
  <option value="REPREZENTARE COMPANIE">REPREZENTARE COMPANIE</option>
  <option value="REȘEDINȚĂ">REȘEDINȚĂ</option>
  <option value="RESPONSABIL DE SECURITATE">RESPONSABIL DE SECURITATE</option>
  <option value="RESPONSABIL SUBSTANŢE CLASIFICATE">
    RESPONSABIL SUBSTANŢE CLASIFICATE
  </option>
  <option value="REZIDENȚĂ">REZIDENȚĂ</option>
  <option value="REZIDENŢĂ S.U.A.">REZIDENŢĂ S.U.A.</option>
  <option value="REZIDENȚIAT">REZIDENȚIAT</option>
  <option value="SCHIMBARE SEDIU SOCIAL">SCHIMBARE SEDIU SOCIAL</option>
  <option value="ȘCOALĂ AUTO/MOTO">ȘCOALĂ AUTO/MOTO</option>
  <option value="ȘCOLARIZARE">ȘCOLARIZARE</option>
  <option value="SEMNĂTURĂ ELECTRONICĂ">SEMNĂTURĂ ELECTRONICĂ</option>
  <option value="SERVICE CASE DE MARCAT">SERVICE CASE DE MARCAT</option>
  <option value="SINDICAT">SINDICAT</option>
  <option value="SOCIETATE">SOCIETATE</option>
  <option value="SOCIETATE COMERCIALĂ/PROFESIONALĂ">
    SOCIETATE COMERCIALĂ/PROFESIONALĂ
  </option>
  <option value="SOCIETATEA DE INVESTIȚII FINANCIARE">
    SOCIETATEA DE INVESTIȚII FINANCIARE
  </option>
  <option value="SOCIETATEA DE MEDICINĂ LEGALĂ">
    SOCIETATEA DE MEDICINĂ LEGALĂ
  </option>
  <option value="SOCIETATEA DE ȘTIINȚE MATEMATICE">
    SOCIETATEA DE ȘTIINȚE MATEMATICE
  </option>
  <option value="SOCIETATEA ROMÂNĂ DE STATISTICĂ">
    SOCIETATEA ROMÂNĂ DE STATISTICĂ
  </option>
  <option value="SOCIETATEA SCRIITORILOR ROMÂNI">
    SOCIETATEA SCRIITORILOR ROMÂNI
  </option>
  <option value="TRANSPORT MARFĂ/VALORI/PERSOANE">
    TRANSPORT MARFĂ/VALORI/PERSOANE
  </option>
  <option value="UNIUNEA ARHITECȚILOR DIN ROMÂNIA">
    UNIUNEA ARHITECȚILOR DIN ROMÂNIA
  </option>
  <option value="UNIUNEA ARTIȘTILOR PLASTICI">
    UNIUNEA ARTIȘTILOR PLASTICI
  </option>
  <option value="UNIUNEA EUROPEANĂ">UNIUNEA EUROPEANĂ</option>
  <option value="UNIUNEA JURIȘTILOR DIN ROMÂNIA">
    UNIUNEA JURIȘTILOR DIN ROMÂNIA
  </option>
  <option value="UNIUNEA NAȚIONALĂ A BAROURILOR DIN ROMÂNIA">
    UNIUNEA NAȚIONALĂ A BAROURILOR DIN ROMÂNIA
  </option>
  <option value="UNIUNEA TRANSPORTATORILOR">UNIUNEA TRANSPORTATORILOR</option>
  <option value="UNIUNEA ZIARIŞTILOR PROFESIONIŞTI">
    UNIUNEA ZIARIŞTILOR PROFESIONIŞTI
  </option>
  <option value="VALIDARE CONSILIER/PRIMAR">VALIDARE CONSILIER/PRIMAR</option>
  <option value="VIZĂ">VIZĂ</option>
  <option value="VIZĂ DE LUCRU">VIZĂ DE LUCRU</option>
  <option value="VIZĂ S.U.A">VIZĂ S.U.A</option>
  <option value="VIZĂ ȘEDERE ÎN STRĂINĂTATE">VIZĂ ȘEDERE ÎN STRĂINĂTATE</option>
  <option value="VIZĂ ȘEDERE TEMPORARĂ">VIZĂ ȘEDERE TEMPORARĂ</option>
  <option value="VIZITĂ PENITENCIAR STRĂINĂTATE">
    VIZITĂ PENITENCIAR STRĂINĂTATE
  </option>
  <option value="VOLUNTARIAT">VOLUNTARIAT</option></select>
</div>
    );
}

export default MotivSolicitare;