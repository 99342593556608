import React, { useState } from 'react';
import documentImg from './../assets/kyc-01.webp';
import ownerImg from './../assets/kyc-03.webp';

function ImageUploader({ setImages }) {
  const [previewImages, setPreviewImages] = useState({
    document: documentImg,
    owner: ownerImg
  });
  const [uploading, setUploading] = useState({
    document: false,
    owner: false
  });
  const [uploadSuccess, setUploadSuccess] = useState({
    document: false,
    owner: false
  });

  const handleImageChange = (e, imageKey) => {
    const file = e.target.files[0];
    if (file) {
      // Simulate file upload
      setUploading(prev => ({ ...prev, [imageKey]: true }));

      // Mock upload process
      setTimeout(() => {
        // Set file for upload
        setImages(prev => ({ ...prev, [imageKey]: file }));

        // Read file for preview
        const reader = new FileReader();
        reader.onload = (e) => {
          setPreviewImages(prev => ({ ...prev, [imageKey]: e.target.result }));
          setUploading(prev => ({ ...prev, [imageKey]: false }));
          setUploadSuccess(prev => ({ ...prev, [imageKey]: true }));

          // Reset success state after a delay
          setTimeout(() => {
            setUploadSuccess(prev => ({ ...prev, [imageKey]: false }));
          }, 3000);
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
          setUploading(prev => ({ ...prev, [imageKey]: false }));
        };
        reader.readAsDataURL(file);
      }, 2000);
    }
  };

  return (
    <div className="flex flex-col gap-6 mb-6">
      <h1 className="font-semibold text-2xl">Documentele necesare</h1>
      <div className="w-full p-4 rounded-lg shadow-sm border-2 border-gray-500/10">
        <div className="flex flex-col md:grid md:grid-cols-12 gap-3 md:gap-6">
          {Object.keys(previewImages).map((key) => (
            <div className="col-span-6" key={key}>
              <div className="flex flex-col items-center w-full">
                <div className="flex justify-center items-center relative w-60 h-60 mb-4 rounded-lg bg-indigo-800">
                  <img
                    src={previewImages[key]}
                    alt={`${key} Preview`}
                    className="absolute w-full h-full object-contain rounded-md"
                  />
                </div>
                <label htmlFor={`${key}_upload`} className="block mb-2 text-sm font-medium text-gray-900 w-fit text-center">
                  {key === 'document' ? 'Document de identitate (Buletin/Pașaport)' : 'Dvs. ținând documentul de identitate în mână'}
                </label>
                <input
                  id={`${key}_upload`}
                  type="file"
                  accept="image/*, application/pdf, image/heic, image/heif"
                  multiple=""
                  className="hidden"
                  onChange={(e) => handleImageChange(e, key)}
                />
                {uploadSuccess[key] ? (
                  <button
                    type="button"
                    className="flex justify-center items-center py-2 px-4 rounded-full shadow-sm text-md border border-green-400 text-green-600 bg-green-50"
                  >
                    Succes
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => document.getElementById(`${key}_upload`).click()}
                    className={`flex justify-center items-center py-2 px-4 rounded-full shadow-sm text-md ${uploading[key] ? 'border border-blue-400 text-blue-600 bg-blue-50' : 'border border-indigo-800 text-indigo-800 hover:bg-indigo-800 hover:text-white'}`}
                  >
                    {uploading[key] ? 'Încărcare...' : 'Încarcă'}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImageUploader;
