const PricePage = () => {

    return (<div className="w-full grow">
        <section className="bg-indigo-800">
          <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-32 lg:px-12">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
              Servicii de Calitate, Tarife Accesibile
            </h1>
            <p className="text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48">
              Economisește timp și bani cu servicii digitale de înaltă calitate
            </p>
          </div>
        </section>
        <section className="bg-gray-50">
          <div className="py-8 px-4 mx-auto max-w-screen-lg text-center lg:py-32 lg:px-12 flex flex-col gap-12">
            <div>
              <div className="mb-4 sm:mb-6">
                <h2 className="text-3xl sm:text-5xl leading-10 font-bold tracking-tight text-gray-900 sm:tracking-tight text-left">
                  Cazier Judiciar PF/PJ
                </h2>
              </div>
              <div className="flex flex-col gap-4 text-left">
                <div className="bg-white px-4 py-5 sm:px-6 rounded-lg shadow flex flex-col gap-3 md:gap-0 md:flex-row md:justify-between md:items-center">
                  <div className="md:flex md:flex-row md:justify-between md:flex-1 md:flex-wrap md:items-center">
                    <h3 className="text-lg sm:text-xl font-semibold">
                      Cazier Judiciar
                    </h3>
                    <div className="ml-auto mr-0 md:mr-4 font-bold text-lg">
                      210 lei + TVA
                    </div>
                    <div className="w-full pl-4 md:pl-8 text-gray-600">
                      <div className="w-full flex justify-between">
                        <div>+ Serviciu Rapid</div>
                        <div className="mr-4">84 lei + TVA</div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="whitespace-nowrap inline-flex items-center justify-center relative px-2 py-1 lg:px-6 lg:py-2 border border-transparent rounded-md shadow-sm text-base font-semibold w-full md:w-auto bg-fuchsia-600 hover:bg-fuchsia-700 focus:ring-fuchsia-800 focus:border-fuchsia-600 text-white disabled:bg-gray-400"
                  >
                    <span className="">Obține Acum</span>
                  </button>
                </div>
                <div className="bg-white px-4 py-5 sm:px-6 rounded-lg shadow flex flex-col gap-3 md:gap-0 md:flex-row md:justify-between md:items-center">
                  <div className="md:flex md:flex-row md:justify-between md:flex-1 md:flex-wrap md:items-center">
                    <h3 className="text-lg sm:text-xl font-semibold">
                      Cazier Judiciar + Traducere Legalizata
                    </h3>
                    <div className="ml-auto mr-0 md:mr-4 font-bold text-lg">
                      462 lei + TVA
                    </div>
                    <div className="w-full pl-4 md:pl-8 text-gray-600">
                      <div className="w-full flex justify-between">
                        <div>+ Serviciu Rapid</div>
                        <div className="mr-4">84 lei + TVA</div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="whitespace-nowrap inline-flex items-center justify-center relative px-2 py-1 lg:px-6 lg:py-2 border border-transparent rounded-md shadow-sm text-base font-semibold w-full md:w-auto bg-fuchsia-600 hover:bg-fuchsia-700 focus:ring-fuchsia-800 focus:border-fuchsia-600 text-white disabled:bg-gray-400"
                  >
                    <span className="">Obține Acum</span>
                  </button>
                </div>
                <div className="bg-white px-4 py-5 sm:px-6 rounded-lg shadow flex flex-col gap-3 md:gap-0 md:flex-row md:justify-between md:items-center">
                  <div className="md:flex md:flex-row md:justify-between md:flex-1 md:flex-wrap md:items-center">
                    <h3 className="text-lg sm:text-xl font-semibold">
                      Cazier Judiciar + Traducere Legalizata + Apostila Haga
                    </h3>
                    <div className="ml-auto mr-0 md:mr-4 font-bold text-lg">
                      630 lei + TVA
                    </div>
                    <div className="w-full pl-4 md:pl-8 text-gray-600">
                      <div className="w-full flex justify-between">
                        <div>+ Serviciu Rapid</div>
                        <div className="mr-4">84 lei + TVA</div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="whitespace-nowrap inline-flex items-center justify-center relative px-2 py-1 lg:px-6 lg:py-2 border border-transparent rounded-md shadow-sm text-base font-semibold w-full md:w-auto bg-fuchsia-600 hover:bg-fuchsia-700 focus:ring-fuchsia-800 focus:border-fuchsia-600 text-white disabled:bg-gray-400"
                  >
                    <span className="">Obține Acum</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      );
};

export default PricePage;