import React, { useEffect, useState, useCallback } from 'react';
import ToggleButton from '../components/ToggleButton';
import { useNavigate } from 'react-router-dom';
import ImageUploader from '../components/ImageUploader';
import { API_URL } from '../config';
import axios from 'axios';
import DeliveryOptions from '../components/DeliveryOptions';

function validateImages(image) {
  return image !== null;
}

function validateFields(field, fieldData) {
  if (field === "images") return validateImages(fieldData);
  return fieldData !== undefined && fieldData.trim() !== '';
}

const OptionsPage = ({ idProcesareClient, options, setOptions, setProgress, images, setImages, submitted }) => {
    const basePrice= 210;
    const [total, setTotal] = useState(basePrice);
    const [formErrors, setFormErrors] = useState({tara_utilizare: false,
        limba_traducere: false, document: false, owner: false});
    const prices = {"traducere legalizata": 0,'time': 0, "apostila haga": 158, "tara_haga": 0, "urgent": 84, "electronic": 0, "tara_utilizare": 252, "limba_traducere":0, "expert": 49, 'livrare':0};

  useEffect(() => {
    calculateTotal(options);
  }, [options]);

  const handleOptionChange = (option) => {
    const updatedOptions = { ...options };
    updatedOptions[option] = !options[option];

    // Handle dependencies between options
    if (option === "tara_utilizare" && !updatedOptions[option]) {
      updatedOptions['apostila haga'] = false;
    }
    if (option === "apostila haga" && updatedOptions[option] && !options['tara_utilizare']) {
      updatedOptions['tara_utilizare'] = true;
    }

    setOptions(updatedOptions);
    calculateTotal(updatedOptions);
  };

    const handleOptionTextChange = (option, value) => {
        const newOptions = { ...options, [option]: value};
        const isValid = validateFields(option, value);
        setOptions(newOptions);
        calculateTotal(newOptions);
        setFormErrors({ ...formErrors, [option]: !isValid });
        let totalThings = 2, totalCorrect = 0;
        let isImage1Valid = validateFields('images', images.owner);
        let isImage2Valid = validateFields('images', images.document);
        if (isImage1Valid) totalCorrect++;
        if (isImage2Valid) totalCorrect++;
        if (option === "tara_haga" || option === "limba_traducere" ) {
            totalThings++;
            if(isValid) totalCorrect++;
        }
        console.log(totalCorrect, totalThings);
        let prog = Math.min((totalCorrect/ totalThings ) * 100, 100);
        setProgress(prog);
    }

    const checkValidity = useCallback(() => {
        let totalThings = 2, totalCorrect = 0;
        let isImage1Valid = validateFields('images', images.owner);
        let isImage2Valid = validateFields('images', images.document);
        if (isImage1Valid) totalCorrect++;
        if (isImage2Valid) totalCorrect++;
        setFormErrors(prev => ({...prev, image1: isImage1Valid, image2: isImage2Valid}));
        if (options.tara_utilizare){
            totalThings++;
            if (validateFields("txt", options.limba_traducere))
                totalCorrect++;
            else setFormErrors(prev => ({...prev, limba_traducere: true}));
        }
        if (options["apostila haga"]){
            totalThings++;
            if (validateFields("txt", options.tara_haga))
                totalCorrect++;
            else setFormErrors(prev => ({...prev, tara_haga: true}));
        }
      }, [options, images]); 
      
      useEffect(() => {
        if (submitted) {
          checkValidity();
        }
      }, [submitted, checkValidity]); 

      useEffect(() => {
        let totalThings = 2, totalCorrect = 0;
        let isImage1Valid = validateFields('images', images.owner);
        let isImage2Valid = validateFields('images', images.document);
        if (isImage1Valid) totalCorrect++;
        if (isImage2Valid) totalCorrect++;
        setFormErrors(prev => ({...prev, image1: isImage1Valid, image2: isImage2Valid}));
        if (options.tara_utilizare){
            totalThings++;
            if (validateFields("txt", options.limba_traducere))
                totalCorrect++;
            else setFormErrors(prev => ({...prev, limba_traducere: true}));
        }
        if (options["apostila haga"]){
            totalThings++;
            if (validateFields("txt", options.tara_haga))
                totalCorrect++;
            else setFormErrors(prev => ({...prev, tara_haga: true}));
        }
        let prog = Math.min((totalCorrect/ totalThings ) * 100, 100);
        setProgress(prog);
      }, [images]);

    const calculateTotal = (options) => {
        let sum = basePrice;
        Object.keys(options).forEach(key => {
            if (options[key]) {
                sum += prices[key];
            }
            if (key === 'livrare' && options[key]['delivery_country']!=='Romania'){
                sum += 250;
            }
        });
        setTotal(sum);
    };

  return (
    <div className='mx-auto max-w-7xl pb-10 sm:py-16 sm:px-6 lg:px-8 w-full grow'>
    <div className="flex rounded-md py-4 md:py-8 items-center bg-white justify-center">
      <form className="w-full lg:w-4/6 px-4 md:px-8 py-4">
        <div className="flex flex-col gap-6 mb-6">
          <h1 className="font-semibold text-2xl text-left">Opțiuni</h1>
          <div className="w-full p-4 rounded-lg shadow-sm border-2 border-gray-500/10">
            <div className="flex flex-col md:grid md:grid-cols-12 gap-3 md:gap-6">
              {/* Checkbox for 'tara_utilizare' */}
              <div className="col-span-4">
                <div className="sm:my-3 text-sm">
                  <div className="flex items-center cursor-pointer select-none">
                    <input
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 cursor-pointer text-indigo-600 focus:ring-indigo-300"
                      type="checkbox"
                      id="tara_utilizare"
                      checked={options['tara_utilizare']}
                      onChange={() => handleOptionChange('tara_utilizare')}
                    />
                    <label htmlFor="tara_utilizare" className="ml-2 text-base sm:text-sm font-medium text-gray-900 cursor-pointer">
                      Traducere legalizată
                    </label>
                  </div>
                </div>
              </div>

              {/* Checkbox for 'apostila haga' */}
              <div className="col-span-3">
                <div className="sm:my-3 text-sm">
                  <div className="flex items-center cursor-pointer select-none">
                    <input
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 cursor-pointer text-indigo-600 focus:ring-indigo-300"
                      type="checkbox"
                      id="apostila_haga"
                      checked={options['apostila haga']}
                      onChange={() => handleOptionChange('apostila haga')}
                    />
                    <label htmlFor="apostila_haga" className="ml-2 text-base sm:text-sm font-medium text-gray-900 cursor-pointer">
                      Apostilă Haga
                    </label>
                  </div>
                </div>
              </div>

              {/* Select for 'limba_traducere' */}
              <div className="col-span-5">
                <div className="relative handle-outside-click">
                  <select
                    disabled={!options['tara_utilizare']}
                    className="input block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    name="limba_traducere"
                    value={options.limba_traducere}
                    onChange={(e) => handleOptionTextChange("limba_traducere", e.target.value)}
                  >
                    <option value="" disabled>Alege Limba</option>
                    <option value="Albania">Albania</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Greece">Greece</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Italy">Italy</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Malta">Malta</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="North Macedonia">North Macedonia</option>
                    <option value="Norway">Norway</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Spain">Spain</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Vatican City">Vatican City</option>
                    <option value="Alta optiune">Alta optiune</option>
                  </select>
                </div>
              </div>

              {/* Text Input for 'tara_haga' */}
              <div className="col-span-12">
                {options['apostila haga'] && (
                  <div className="sm:my-3 text-sm">
                    <input
                      type="text"
                      name="tara_haga"
                      value={options.tara_haga}
                      onChange={(e) => handleOptionTextChange("tara_haga", e.target.value)}
                      placeholder="Introduceți țara"
                      className={`input block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 ${formErrors.tara_haga ? 'border-red-500' : ''}`}
                    />
                    {submitted && formErrors.tara_haga && <p className="text-red-500 text-xs italic">Te rog introdu o țară.</p>}
                  </div>
                )}
              </div>

              {/* ToggleButton for 'urgent' */}
              <div className="col-span-12">
                <label className="block text-gray-700 font-bold">Doriți documentul în regim ...</label>
                <ToggleButton
                  leftSelected={options.urgent}
                  handleFormTypeChange={() => handleOptionChange("urgent")}
                  leftText="Urgent - 1 Zi Lucrătoare"
                  rightText="Standard - 5 Zile Lucrătoare"
                  vertical={true}
                />
              </div>

              {/* ToggleButton for 'electronic' */}
              <div className="col-span-12">
                <label className="block text-gray-700 font-bold">Livrarea se va face ...</label>
                <ToggleButton
                  leftSelected={options.electronic}
                  handleFormTypeChange={() => handleOptionChange("electronic")}
                  leftText="Electronic"
                  rightText="Electronic + Livrare Standard"
                  vertical={true}
                />
              </div>

              {/* DeliveryOptions if 'electronic' is not selected */}
              {!options.electronic && (
                <div className="col-span-12">
                  <DeliveryOptions options={options} setOptions={setOptions} />
                </div>
              )}

                <div className="col-span-12">
                <div className="sm:my-3 text-sm">
                    <div className="flex items-center cursor-pointer select-none">
                    <input
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 cursor-pointer text-indigo-600 focus:ring-indigo-300"
                        type="checkbox"
                        name="expert"
                        value={options.expert}
                        onChange={(e) => handleOptionChange("expert")}
                    />
                    <div className="ml-2">
                        <div className="text-base sm:text-sm font-medium text-left">
                        Verificare expert (49 lei + TVA)
                        </div>
                        <span className="text-sm block text-left">
                        Un expert juridic va analiza manual cererea ta.
                        </span>
                    </div>
                    </div>
                    <div className="w-fit" />
                </div>
                </div>


              {/* Display total price */}
            </div>
              <div className="w-full flex gap-2 grow lg:grow-0 lg:w-auto text-xl font-extrabold border-2 border-gray-500/10 p-4 px-6 rounded-lg bg-gray-50 justify-end">Cost:<div>{total} lei<small> + TVA</small></div></div>
          </div>
        </div>
        <ImageUploader setImages={setImages} />
      </form>
    </div>
    </div>
  );
};

export const OptionsFooter = ({ progress, options, images, idProcesareClient, raiseError}) => {
    const navigate = useNavigate();

    const uploadImages = async () => {
        const uploadDocument = async () => {
            const formData = new FormData();
            formData.append('image', images.document);
            formData.append('idProcesareClient', idProcesareClient);
    
            try {
                const response = await axios.post(`${API_URL}/api/uploadClientImage/document`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } catch (error) {
                console.error('Document upload failed:', error);
            }
        };
    
        const uploadOwnerID = async () => {
            const formData = new FormData();
            formData.append('image', images.owner);
            formData.append('idProcesareClient', idProcesareClient);
    
            try {
                const response = await axios.post(`${API_URL}/api/uploadClientImage/owner`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } catch (error) {
                console.error('Owner ID upload failed:', error);
            }
        };
    
        // Execute both upload tasks potentially in parallel
        await Promise.all([uploadDocument(), uploadOwnerID()]);
    };    

    const handleSubmit = () => {
        if (progress === 100)
        axios.put(API_URL+'/api/updateClient', {
            formData: options,
            idProcesareClient,
          })
          .then(response => {
            console.log(response.data);
            if (!response.data.error) {
              uploadImages();
              navigate("/signature/"+ idProcesareClient);
              raiseError(false);
            } else {
              console.error('Error:', response.data.error);
              raiseError(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            raiseError(true);
          });
        else {
          raiseError(true);
        }
      };

    return (
        <footer className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex flex-row gap-4 items-center shadow-lg shadow-gray-500/50">
          <div className="w-full bg-gray-200 rounded-full h-4">
          <div
              className={`relative bg-green-600 h-4 rounded-full transition-all ease-in-out duration-500 ${progress === 100 ? 'animate-pulse-green' : ''}`}
              style={{ width: `${progress}%` }}  
            >
            <div className="wave"></div> 
          </div>
          </div>
          <button
            onClick={handleSubmit}
            className={`bg-violet-600 text-white py-2 px-4 rounded ${progress === 100 ? 'animate-pulse-green' : ''} hover:bg-violet-700 hover:shadow-lg transition duration-300`}
          >
            Urmatorul Pas
          </button>
        </footer>
      );
} 

export default OptionsPage;
