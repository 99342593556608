import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import GenerateContract from "./GenerateContract";

import { PDFViewer } from '@react-pdf/renderer';
import DownloadPDFButton from './DownloadPDFButton';
import { API_URL } from '../config';
import axios from 'axios';

const Signature = ({ idProcesareClient, client, setPDFBlob }) => {
    const sigCanvas = useRef(null);
    const [signatureImage, setSignatureImage] = useState(null);
    const [canvasWidth, setCanvasWidth] = useState(window.innerWidth > 540 ? 500 : window.innerWidth - 60);

    // Adjust canvas width based on the window size
    useEffect(() => {
        const handleResize = () => {
            setCanvasWidth(window.innerWidth > 540 ? 500 : window.innerWidth - 60);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Clear the signature canvas
    const clear = useCallback(() => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
        setSignatureImage(null);
    }, []);

    // Save the signature to Firebase
    const saveSignature = useCallback(() => {
        if (sigCanvas.current) {
            const image = sigCanvas.current.getTrimmedCanvas().toDataURL('image/signature');
            // Save to Firebase (Example path) 
            setSignatureImage(image); // Set image in state for display
            return image;
        }
    }, [idProcesareClient]);

    const handleBlobReady = useCallback((blob) => {
        setPDFBlob(blob);
      }, []);

    return (
        <div class="mx-auto max-w-7xl pb-10 sm:py-16 sm:px-6 lg:px-8 w-full grow">
        <div className="flex rounded-md py-4 md:py-8 items-center bg-white justify-center">
            <form className="w-full lg:w-4/6 px-4 md:px-8 py-4">
                <div>
                    <div className="flex flex-col gap-6 mb-6">
                        <h1 className="font-semibold text-2xl">Avem nevoie de semnătura ta</h1>
                        <div className="w-full p-4 rounded-lg shadow-sm border-2 border-gray-500/10">
                            <div className="flex flex-col md:grid md:grid-cols-12 gap-3 md:gap-6">
                                <div className="col-span-12">
                                    <div className="">
                                        <div className="flex flex-col gap-4 items-center">
                                            <label htmlFor="signature" className="block font-medium text-gray-900 text-center w-fit mx-auto">
                                                Desenează cu cursorul semnătura ta în chenarul de mai jos. Această împuternicire ne oferă acordul dvs. pentru a putea elibera documentul solicitat.
                                            </label>
                                            <SignatureCanvas
                                                penColor='black'
                                                canvasProps={{
                                                    width: canvasWidth,
                                                    height: 300,
                                                    className: 'border-2 rounded-md shadow-md touch-none select-none ',
                                                    style: { touchAction: 'none', userSelect: 'none' }
                                                }}
                                                ref={sigCanvas}
                                                onEnd={saveSignature}
                                            />
                                            <button type="button" onClick={clear} className="flex justify-center items-center py-2 px-4 rounded-full shadow-sm text-md border focus:outline-none focus:ring-2 focus:ring-offset-2 border-indigo-800 hover:bg-indigo-800 text-indigo-800 hover:text-white disabled:bg-gray-400">
                                                Resetează Semnătura
                                            </button>
                                            {signatureImage && <DownloadPDFButton userData={client} beneficiarSignature={signatureImage} onBlobReady={handleBlobReady}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </form>
            {/* <PDFViewer style={{ width: '100%', height: '90vh' }}>
                <GenerateContract userData={{name:"Dan"}} beneficiarSignature={signatureImage} />
            </PDFViewer>
            {signatureImage && <img src={signatureImage} alt="Signature Preview" />} */}
        </div>
        </div>
    );
};


export const SignatureFooter = ({contract, idProcesareClient}) => {
  const navigate = useNavigate();

  const uploadImages = async () => {
    
    const uploadContract= async () => {
        const formData = new FormData();
        formData.append('image', contract);
        formData.append('idProcesareClient', idProcesareClient);

        try {
            const response = await axios.post(`${API_URL}/api/uploadClientImage/contract`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Document upload successful:', response.data);
        } catch (error) {
            console.error('Document upload failed:', error);
        }
    };

    await Promise.all([uploadContract()]);
}

  const handleSubmit = () => {
    if (contract != null) {
    uploadImages();
    navigate("/billing/"+ idProcesareClient);
    }
  };

  return (
      <footer className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex flex-row gap-4 items-center shadow-lg shadow-gray-500/50">
        <div className="w-full h-4">
       
        </div>
        <button
          onClick={handleSubmit}
          className={`bg-violet-600 text-white ${contract != null ? 'animate-pulse-green' : ''} py-2 px-4 rounded hover:bg-violet-700 hover:shadow-lg transition duration-300`}
        >
          Urmatorul Pas
        </button>
      </footer>
    );
} 

export default Signature;
