import React from 'react';

const checkSvg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className="w-6 h-6 mr-1 fill-indigo-600"><path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clipRule="evenodd"></path></svg>;
const exSvg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className="w-6 h-6 mr-1 fill-indigo-600"><path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z" clipRule="evenodd"></path></svg>;

const Revizuire = ({ formData, total }) => {
  const { invoice, client, product, idProcesareClient } = formData;

  return (
    <div className="flex flex-col rounded-xl p-4 border shadow justify-between border-indigo-300 bg-indigo-100">
      <div>
        <h2 className="font-semibold text-2xl mb-2 border-b-2 border-indigo-300">Revizuire comanda #{idProcesareClient}</h2>
        <div className="w-full grid md:grid-cols-2 gap-4">
          {/* Always visible information */}
          <div>
            <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Tipul documentului </p>
            <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">Cazier Judiciar</p></div>
          </div>
          <div>
            <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Tipul clientului </p>
            <div className="flex items-center">
              {client.legalEntity ? <p className="pt-[2px] text-sm font-medium text-gray-500">Persoană Juridică</p> : <p className="pt-[2px] text-sm font-medium text-gray-500">Persoană Fizică</p>}
              </div>
          </div>

          {/* Conditional information based on user options */}
          {product.electronic ? (
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Livrare </p>
              <div className="flex items-center">{checkSvg}<p className="pt-[2px] text-sm font-medium text-gray-500">Electronic</p></div>
            </div>
          ) : (
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Livrare </p>
              <div className="flex items-center">{checkSvg}<p className="pt-[2px] text-sm font-medium text-gray-500">Standard + Electronic</p></div>
            </div>
          ) }

          {product.urgent ? (
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Prioritate </p>
              <div className="flex items-center">{checkSvg}<p className="pt-[2px] text-sm font-medium text-gray-500">Urgent - 1 Zi Lucrătoare</p></div>
            </div>
          ) : (
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Prioritate </p>
              <div className="flex items-center">{checkSvg}<p className="pt-[2px] text-sm font-medium text-gray-500">Standard - 5 Zile Lucrătoare</p></div>
            </div>
          )}

          {product["apostila haga"] ? (
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Apostilă Haga </p>
              <div className="flex items-center">{checkSvg}<p className="pt-[2px] text-sm font-medium text-gray-500">DA</p></div>
            </div>
          ) :  <div>
          <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Apostilă Haga </p>
          <div className="flex items-center">{exSvg}<p className="pt-[2px] text-sm font-medium text-gray-500">NU</p></div>
        </div>}

          {product.tara_utilizare ? (
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Traducere </p>
              <div className="flex items-center">{checkSvg}<p className="pt-[2px] text-sm font-medium text-gray-500">{product.limba_traducere}</p></div>
            </div>
          ) :  <div>
          <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Traducere </p>
          <div className="flex items-center">{exSvg}<p className="pt-[2px] text-sm font-medium text-gray-500">NU</p></div>
        </div>}
          {client.legalEntity ?
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Firma </p>
              <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">{client.firmName}</p></div>
            </div>: 
            <div>
            <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Nume </p>
            <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">{client.firstName} {client.lastName}</p></div>
          </div>}

          <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Motivul solicitării </p>
              <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">{client.motive}</p></div>
            </div>  
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Email </p>
              <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">{client.email}</p></div>
            </div>  
            <div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Telefon </p>
              <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">{client.phone}</p></div>
            </div>  
            { client.id &&<div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> CNP </p>
              <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">{client.id}</p></div>
            </div>  
            }
            { client.firmName &&<div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> Nume Firmă </p>
              <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">{client.firmName}</p></div>
            </div>  
            }
            { client.firmCUI &&<div>
              <p className="pt-2 pb-1 text-base leading-5 font-bold text-gray-900"> CUI Firmă </p>
              <div className="flex items-center"><p className="pt-[2px] text-sm font-medium text-gray-500">{client.firmCUI}</p></div>
            </div>  
            }
        </div>
      </div>

      <div className="mt-3">
        <div className="flex flex-col md:flex-row md:items-center justify-between border-t-2 w-full py-4 gap-2 border-indigo-300">
          <strong className="text-gray-700">Total de plată:</strong>
          <strong className="text-gray-700">{total} lei</strong>
        </div>
      </div>
    </div>
  );
};

export default Revizuire;
