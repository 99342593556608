import React, { useState } from 'react';

const DeliveryOptions = ({ options, setOptions }) => {
    const initialDeliveryState = {
        delivery_telephone: '',
        delivery_country: '',
        delivery_city: '',
        delivery_zipcode: '',
        delivery_address: '',
        delivery_other_details: ''
    };

    // Initialize the delivery details state
    const [deliveryDetails, setDeliveryDetails] = useState(initialDeliveryState);

    // Handle input changes and update the corresponding state
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setDeliveryDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));

        // Update options state in the parent component
        setOptions(prevOptions => ({
            ...prevOptions,
            livrare: {
                ...prevOptions.livrare,
                [name]: value
            }
        }));
    };

    return (
        <div className="w-full p-4 rounded-lg shadow-sm border border-gray-200 space-y-4">
            <h1 className="font-semibold text-2xl">Livrare gratuită în România prin FanCourier și livrare internațională pentru 200 RON + TVA</h1>
            <div className="flex flex-col md:grid md:grid-cols-12 gap-6">
                <div className="col-span-12">
                    <label htmlFor="delivery_telephone" className="block mb-2 text-sm font-medium text-gray-900">Telefon</label>
                    <input
                        name="delivery_telephone"
                        type="text"
                        placeholder="Numărul de telefon"
                        className="w-full p-3 border rounded bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
                        value={deliveryDetails.telephone}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-span-6">
                    <label htmlFor="delivery_country" className="block mb-2 text-sm font-medium text-gray-900">Țara</label>
                    <select
                        name="delivery_country"
                        className="w-full p-3 border rounded bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
                        value={deliveryDetails.country}
                        onChange={handleInputChange}
                    >
                        <option value="">Alege țara</option>
                        <option value="Austria">Austria</option><option value="Belgia">Belgia</option>
                        <option value="Bulgaria">Bulgaria</option><option value="Cipru">Cipru</option>
                        <option value="Croația">Croația</option><option value="Danemarca">Danemarca</option>
                        <option value="Estonia">Estonia</option><option value="Finland">Finland</option>
                        <option value="Franța">Franța</option><option value="Germania">Germania</option>
                        <option value="Grecia">Grecia</option><option value="Irlanda">Irlanda</option>
                        <option value="Islanda">Islanda</option><option value="Italia">Italia</option>
                        <option value="Liechtenstein">Liechtenstein</option><option value="Lituania">Lituania</option>
                        <option value="Luxemburg">Luxemburg</option><option value="Malta">Malta</option>
                        <option value="Norvegia">Norvegia</option><option value="Polonia">Polonia</option>
                        <option value="Portugalia">Portugalia</option><option value="Republica Cehă">Republica Cehă</option><option value="Romania">Romania</option>
                        <option value="Slovacia">Slovacia</option><option value="Slovenia">Slovenia</option><option value="Spania">Spania</option>
                        <option value="Suedia">Suedia</option><option value="Țările de Jos">Țările de Jos</option><option value="Ungaria">Ungaria</option>
                    </select>
                </div>
                <div className="col-span-6">
                    <label htmlFor="delivery_city" className="block mb-2 text-sm font-medium text-gray-900">Oraș</label>
                    <input
                        name="delivery_city"
                        type="text"
                        className="w-full p-3 border rounded bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
                        value={deliveryDetails.city}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-span-4">
                    <label htmlFor="delivery_zipcode" className="block mb-2 text-sm font-medium text-gray-900">Cod poștal</label>
                    <input
                        name="delivery_zipcode"
                        type="text"
                        className="w-full p-3 border rounded bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
                        value={deliveryDetails.zipcode}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-span-8">
                    <label htmlFor="delivery_address" className="block mb-2 text-sm font-medium text-gray-900">Adresa</label>
                    <input
                        name="delivery_address"
                        type="text"
                        placeholder="Strada, Număr etc."
                        className="w-full p-3 border rounded bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
                        value={deliveryDetails.address}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-span-12">
                    <label htmlFor="delivery_other_details" className="block mb-2 text-sm font-medium text-gray-900">Alte Detalii</label>
                    <input
                        name="delivery_other_details"
                        type="text"
                        className="w-full p-3 border rounded bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
                        value={deliveryDetails.other_details}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default DeliveryOptions;
