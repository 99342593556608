const TermeniPage = () => {

    return (<div className="relative py-16 bg-white overflow-hidden grow w-full">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-4xl mx-auto">
            <h1 className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
              Politica de confidențialitate
            </h1>
          </div>
          <div className="mt-6 max-w-4xl text-gray-500 mx-auto">
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              1. Generale
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              1.1. Noi, WISE SRL („Compania” sau „Operatorul”), adresa de e-mail:
              („Adresa de e-mail”), procesăm datele dumneavoastră cu caracter personal
              ca parte a utilizării de către dumneavoastră a produselor noastre
              („Produse”) sau platformei („Platformă” și, împreună cu Produsele,
              „Servicii”). Vă asigurăm că prelucrăm datele dumneavoastră cu caracter
              personal într-un mod confidențial și responsabil. Prelucrarea datelor
              dumneavoastră cu caracter personal se efectuează în conformitate cu
              Regulamentul General de Protecție a Datelor („GDPR”) și cu legislația
              română și europeană privind protecția datelor cu caracter personal.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              1.2. Prelucrarea datelor cu caracter personal se face în condiții de
              legalitate, echitate și transparență, cu asigurarea securității adecvate
              a datelor, inclusiv protecția împotriva prelucrării neautorizate sau
              ilegale și împotriva pierderii, a distrugerii sau a deteriorării
              accidentale. Personalul nostru angajat respectă cu strictețe cerințele
              legale în privința protecției datelor cu caracter personal și are grijă
              ca toate operațiunile de prelucrare să fie realizate numai în interesul
              dumneavoastră.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              1.3. În această Politică de confidențialitate dorim să vă oferim
              informații despre Companie, natura, domeniul de aplicare și scopurile
              colectării și utilizării datelor, pentru a vă oferi o perspectivă asupra
              prelucrării datelor dumneavoastră cu caracter personal.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              1.4. Pentru Produsele noastre, vom procesa datele dumneavoastră numai în
              conformitate cu scopurile și mijloacele pe care le determinați. În
              aceste cazuri, vă vom furniza acorduri separate de prelucrare a datelor
              cu caracter personal.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              1.5. Operatorul pentru prelucrarea datelor dumneavoastră cu caracter
              personal în sensul GDPR este Compania. Ne puteți contacta la adresa de
              corespondență de mai jos sau prin e-mail folosind adresa de e-mail.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              2. Despre noi
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Operatorul: USETECH SRL
              <br />
              Sediul social: Vasile Alecsandri 38, Voluntari, Ilfov
              <br />
              Număr de înregistrare la Registrul Comerţului de pe lângă Tribunalul
              Bucuresti:J23/1846/2023
              <br />
              Cod unic de înregistrare fiscală: RO47838209
              <br />
              E-mail: asistenta@digigov.ro
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Datele de contact ale responsabilului cu protecția datelor:
              <br />
              Adresa de corespondența: Str. Stănilești nr. 56, sector 1, București
              <br />
              E-mail: dpo@digigov.ro
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              3. Principii de prelucrare a datelor cu caracter personal
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              3.1. Pentru orice prelucrare a datelor cu caracter personal efectuată vă
              asigurăm că datele dumneavoastră sunt:
            </p>
            <ul className="marker:text-gray-300 list-disc list-inside">
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                prelucrate în mod legal, echitabil și transparent;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                colectate în scopuri determinate, explicite și legitime și nu sunt
                prelucrate ulterior într-un mod incompatibil cu aceste scopuri;
                prelucrarea ulterioară în scopuri de arhivare în interes public, în
                scopuri de cercetare științifică sau istorică ori în scopuri
                statistice nu este considerată incompatibilă cu scopurile inițiale;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                adecvate, relevante și limitate la ceea ce este necesar în raport cu
                scopurile în care sunt prelucrate;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                exacte și, în cazul în care este necesar, actualizate; în acest sens
                vom lua toate măsurile necesare pentru a ne asigura că datele cu
                caracter personal care sunt inexacte, având în vedere scopurile pentru
                care sunt prelucrate, sunt șterse sau rectificate fără întârziere;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                păstrate într-o formă care permite identificarea dumneavoastră pe o
                perioadă care nu depășește perioada necesară îndeplinirii scopurilor
                în care sunt prelucrate datele; datele cu caracter personal vor fi
                stocate pe perioade mai lungi în măsura în care acestea vor fi
                prelucrate exclusiv în scopuri de arhivare în interes public, în
                scopuri de cercetare științifică sau istorică ori în scopuri
                statistice;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                prelucrate într-un mod care asigură securitatea adecvată, inclusiv
                protecția împotriva prelucrării neautorizate sau ilegale și împotriva
                pierderii, a distrugerii sau a deteriorării accidentale, prin luarea
                de măsuri tehnice sau organizatorice corespunzătoare.
              </li>
            </ul>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              3.2. Datele dumneavoastră cu caracter personal vor fi păstrate într-o
              formă care permite identificarea dumneavoastră pe o perioadă care nu
              depășește perioada necesară îndeplinirii scopurilor în care sunt
              prelucrate datele. După această perioadă, datele dumneavoastră cu
              caracter personal vor fi șterse cu excepția cazului în care este permisă
              stocarea acestora în condițiile legii.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              4. Datele pe care le prelucrăm
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              4.1. Date de identificare: Prelucrăm astfel de date cu caracter personal
              pe care dumneavoastră, în calitate de utilizator al Serviciilor, ni le
              puneți la dispoziție, de exemplu la înregistrare sau atunci când
              utilizați Serviciile („Datele”). Datele de identificare pot fi: nume,
              prenume, adresa de domiciliu, data nașterii, locul nașterii, cetățenie,
              CNP, informații din actul de identitate, semnătura, naționalitate, sex,
              date privind membrii de familie. Date de contact: număr de telefon,
              adresa de e-mail, adresa de domiciliu sau reședință.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              4.2. Utilizarea site-ului web: Dacă vizitați site-ul nostru web,
              procesăm numai datele personale pe care browserul dumneavoastră le
              comunică serverului nostru. Colectăm următoarele date, care ne sunt
              necesare pentru a afișa corect site-ul și pentru a garanta stabilitatea
              și securitatea:
            </p>
            <ul className="marker:text-gray-300 list-disc list-inside">
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Adresa IP;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                marca de dată și oră, diferența de timp față de GMT;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                site-ul solicitat, site-ul de pe care a fost trimisă solicitarea,
                volumul de date transmis;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                starea accesului/codul de stare HTTP;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                browser, operating system, interface, language and version of the
                browser software.
              </li>
            </ul>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              4.3. Date de înregistrare: La înregistrare colectăm și procesăm
              următoarele informații:
            </p>
            <ul className="marker:text-gray-300 list-disc list-inside">
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                detalii înregistrare: data înregistrării, parola;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                informații personale: nume complet; adresa de e-mail; adresa, cod
                poștal, oraș, regiune, țară;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                informații despre companie (opțional): numele companiei, site-ul web,
                codul fiscal;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                informații de plată;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                tipul cardului de credit, ultimele patru cifre, data expirării;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                adresa de e-mail PayPal;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                data plății, codul facturii, moneda, suma;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                plata lunară sau anuală.
              </li>
            </ul>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              4.4. Date de utilizare a produsului: Datele prelucrate în timpul
              utilizării Serviciilor sunt prelucrate de noi numai ca procesator, nu ca
              operator de date cu caracter personal.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              5. De ce prelucrăm datele dumneavoastră
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              5.1. Scop: Datele cu caracter personal sunt prelucrate pentru
              următoarele scopuri („Scopuri”):
            </p>
            <ul className="marker:text-gray-300 list-disc list-inside">
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Prestarea unor servicii: prelucrarea este necesară pentru executarea
                unui contract sau pentru a face demersuri înainte de încheierea unui
                contract;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Îndeplinirea obligațiilor legale: prelucrarea este necesară în vederea
                îndeplinirii unei obligații legale (de exemplu: contabilitate,
                evidență financiară și arhivare);
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Gestionarea relațiilor cu clienții, inclusiv buletine informative;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Securitatea și stabilitatea Serviciilor.
              </li>
            </ul>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              5.2. 5.2. Legalitatea prelucrării: legalitatea prelucrării (Art. 6 GDPR)
              decurge din:
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              5.2.1. consimțământul dvs., în cazul în care v-am solicitat
              consimțământul explicit (alin. 1 lit. a) din GDPR;
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              5.2.2. necesitatea îndeplinirii contractului, deoarece datele
              dumneavoastră sunt necesare pentru o utilizare satisfăcătoare a
              Serviciilor (art. 1 lit. b) din GDPR;
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              5.2.3. prelucrarea este necesară în vederea îndeplinirii unei obligații
              legale care îi revine Companiei (art. 1 lit. c) din GDPR;
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              5.2.4. interesele legitime urmărite de Companie sau de un terț (alin. 1
              lit. f ) din GDPR.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              5.3. Interese legitime: Interesele legitime ale Companiei sunt să
              monitorizeze, să analizeze și să îmbunătățească Serviciile, să vă
              sprijine cu orice pentru a proteja securitatea, integritatea,
              performanța și funcționalitatea Serviciilor și pentru a vă oferi produse
              și servicii personalizate.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              6. Categoriile de destinatari ai datelor cu caracter personal
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              6.1. Utilizare: Folosim Datele pe care dumneavoastră, în calitate de
              utilizator al produsului, ni le-ați furnizat, numai în scopuri descrise.
              În anumite situații punctuale vom apela la parteneri externi.
              Prelucrarea datelor cu caracter personal de către partenerii externi ai
              noștri se va face doar dacă aceștia oferă garanții suficiente pentru
              punerea în aplicare a unor măsuri tehnice și organizatorice adecvate,
              astfel încât prelucrarea să respecte cerințele prevăzute în GDPR și să
              asigure protecția drepturilor persoanei vizate.
            </p>
            <ul className="marker:text-gray-300 list-disc list-inside">
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>Instituții publice și autorități publice</strong>: Prelucrarea
                datelor cu caracter personal în aceste cazuri se va face pentru
                îndeplinirea unei sarcini care servește unui interes public sau care
                rezultă din exercitarea autorității publice și în baza unor
                reglementări legale speciale.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>Avocați, auditori sau alți consultanți externi</strong>:
                Prelucrarea datelor de către aceste categorii profesionale se va face
                doar cu respectarea strică a secretului profesional și a
                confidențialității datelor cu caracter personal.
              </li>
            </ul>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              6.2. Transfer: transmitem Datele către terți numai, dacă acest lucru
              este (i) necesar pentru Scopuri pentru care au fost colectate, de ex.
              atunci când folosim furnizori de servicii, (ii) pentru a răspunde unei
              solicitări din partea unei autorități naționale, (iii) din cauza unei
              hotărâri judecătorești sau (iv) dacă ați consimțit în prealabil.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              6.3. Prestatori de servicii:
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              6.3.1. Pentru unele părți ale Serviciilor noastre, folosim furnizori
              terți pentru a prelucra date pentru noi, cum ar fi Stripe Payments
              Limited;
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              6.3.2. Atunci când utilizați unii dintre furnizorii de servicii, datele
              sunt transferate către destinatari din țări terțe și anume SUA.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              7. Refuzul de a ne furniza datele
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Vom prelucra datelor cu caracter personal doar în condiții de
              legalitate, respectând cu strictețe principiile legate de prelucrarea
              datelor cu caracter personal stabilite de legislația națională și
              europeană. Nu aveți obligația de a ne furniza datele dumneavoastră cu
              caracter personal însă refuzul acestuia va conduce la imposibilitatea
              accesării serviciilor noastre.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              8. Inexistența unui proces decizional automatizat
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Datele cu caracter personal precum și oricare dintre operațiunile de
              procesare din cadrul Serviciilor noastre nu includ un proces decizional
              bazat exclusiv pe prelucrarea automată a datelor, inclusiv crearea de
              profiluri.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              9. Ștergerea și siguranța datelor
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              9.1. Ștergere: Datele vor fi șterse dacă (a) vă revocați consimțământul
              și nu există alt temei de prelucrare sau o obligație legală din partea
              noastră de a stoca datele; (b) Datele nu mai sunt necesare pentru a
              îndeplini contractul de utilizare privind Produsul sau (c) stocarea este
              sau devine nepermisă din punct de vedere legal. O solicitare de ștergere
              nu afectează Datele, dacă stocarea este necesară din punct de vedere
              legal, de exemplu în scopuri contabile.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              9.2. Măsuri de siguranță: Pentru a evita accesul neautorizat la Date și,
              în general, a securiza Datele, aplicăm următoarele măsuri de siguranță:
              transmisie criptată, stocare criptată, un concept de autorizare, un
              concept de copiere de rezervă a datelor și măsuri de siguranță fizică
              pentru servere. Aceste măsuri de siguranță sunt revizuite în mod
              constant pentru a se conforma celor mai recente evoluții tehnologice.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              10. Perioada de stocare a datelor cu caracter personal
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              10.1. Compania asigură confidențialitatea datelor cu caracter personal
              prelucrate în conformitate cu acordul exprimat de dumneavoastră și
              conform prevederilor legale. Accesul la informațiile tratate drept
              confidențiale va fi limitat la acele persoane, care prin natură
              activității desfășurate, este necesar să ia cunoștință de aceste
              informații în scopul ducerii la îndeplinire a scopului, raporturilor
              juridice născute în relație cu Operatorul. Aceste persoane sunt ținute
              să respecte caracterul confidențial al acestor informații, asumându-și
              la rândul lor obligația de a asigura și păstra confidențialitatea
              acestor date și informații și de a le prelucra în conformitate cu
              cerințele legale.
            </p>
            <ul className="marker:text-gray-300 list-disc list-inside">
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Datele care sunt necesare în scopuri legate de serviciile oferite vor
                fi stocate pe durata contractului de prestări servicii, respectiv pe
                perioada de timp necesară în vederea îndeplinirii obligațiilor legale;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Datele legate de plăți/facturare vor fi stocate pe o perioadă de 10
                ani, conform Legii nr. 82/1991 privind contabilitatea;
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                Prelucrarea datelor în scop de marketing va avea loc pe durata
                relației contractuale cu Operatorul, precum și după încetarea
                acesteia. În situația în care dumneavoastră vă retrageți
                consimțământul privind marketing direct, datele nu vor mai fi
                prelucrate în acest scop, din momentul retragerii consimțământului.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                De asemenea, datele dumneavoastră pot fi prelucrate și pe durata
                existenței unei obligații legale pentru păstrarea datelor, respectiv
                pe durata de existența a unui alt temei justificativ legal, în
                conformitate cu exigențele art. 6 din GDPR.
              </li>
            </ul>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              11. Informații despre drepturi
            </h2>
            <ul className="marker:text-gray-300 list-disc list-inside">
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>Dreptul de acces</strong>: Aveți dreptul de a obține din
                partea noastră o confirmare că Operatorul prelucrează sau nu datele
                dumneavoastră cu caracter personal iar în caz afirmativ, acces la
                datele respective și la anumite informații stabilite de GDPR.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>Dreptul la rectificarea datelor</strong>: Aveți dreptul de a
                obține din partea noastră rectificarea datelor cu caracter personal
                inexacte. Ţinându-se seama de scopurile în care au fost prelucrate
                datele, aveți dreptul de a obţine completarea datelor cu caracter
                personal care sunt incomplete, inclusiv prin furnizarea unei
                declaraţii suplimentare.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>
                  Dreptul la ștergerea datelor („dreptul de a fi uitat”)
                </strong>
                : Cunoscut în special ca „dreptul de a fi uitat“, acesta se bazează pe
                principiul de a garanta oricărui individ libertatea de a face ce vrea
                cu datele sale personale, inclusiv de a le șterge, dacă nu există un
                motiv convingător sau special pentru continuarea procesării și
                stocării acestora.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>Dreptul la restricționarea prelucrării</strong>:
                Restricționarea prelucrării înseamnă marcarea datelor cu caracter
                personal stocate cu scopul de a limita prelucrarea viitoare a
                acestora.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>Dreptul la portabilitatea datelor</strong>: Scopul acestui
                drept este de a vă oferi posibilitatea efectivă de a avea mai mult
                control asupra datelor cu caracter personal. Dreptul de portabilitate
                a datelor se aplică numai în cazul în care: datele sunt procesate prin
                mijloace automate; dumneavoastră v-ați dat consimțământul pentru
                prelucrare; prelucrarea este necesară pentru a îndeplini condițiile
                stipulate printr-un contract.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>Dreptul de opoziție</strong>: Aveți dreptul de a vă opune
                atunci când prelucrarea datelor personale se face în scopuri de
                marketing direct; pentru realizarea de profiluri; prin mijloace
                automate; sau în scopuri științifice sau istorice.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>
                  Dreptul de a nu fi supus unor decizii automatizate, inclusiv
                  profilarea
                </strong>
                : Pentru a asigura conformitatea cu acest drept, Compania va
                identifica în permanență dacă oricare dintre operațiunile procesare
                include un proces automat de luare a deciziilor și va actualiza
                procedurile în consecință pentru a răspunde cerințelor GDPR.
              </li>
              <li className="mt-6 text-lg text-gray-900 lg:text-xl font-light lg:leading-relaxed">
                <strong>Dreptul de a vă retrage consimțământul</strong> în orice
                moment, fără a afecta legalitatea prelucrării efectuate pe baza
                consimțământului înainte de retragerea acestuia în anumite condiții
                expres prevăzute de legislația națională și europeană precum și{" "}
                <strong>
                  dreptul de a depune o plângere în fața unei autorități de
                  supraveghere
                </strong>
                .
              </li>
            </ul>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Pentru a vă exercita oricare dintre drepturi menționate mai sus pentru
              sau pentru a transmite orice fel de întrebare sau nelămurire privind
              aspectele menționate în prezenta Politică de confidențialitate vă puteți
              adresa folosind datele de contact menționate la începutul acestui
              document.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              12. Cookie-uri
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              12.1. Ce sunt cookie-urile? Site-ul web folosește „cookie-uri” – fișiere
              text mici care sunt plasate pe computerul utilizatorului, smartphone-ul
              și/sau stocate de browser. Dacă serverul respectiv al site-ului nostru
              este din nou accesat de către utilizatorul site-ului/produsului,
              browserul utilizatorului trimite cookie-ul primit mai sus înapoi la
              server. Serverul poate evalua informațiile primite în acest mod în
              diferite moduri. Cookie-urile pot fi utilizate, de exemplu, pentru a
              gestiona reclamele pe site sau pentru a facilita navigarea pe o pagină
              web.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              12.2. Dezactivarea cookie-urilor: utilizatorul poate dezactiva
              instalarea cookie-urilor introducând setările corespunzătoare în
              software-ul browserului său (de exemplu, în Internet Explorer, Mozilla
              Firefox, Opera sau Safari). Cu toate acestea, în acest caz, utilizatorul
              poate pune în pericol utilizarea întregii game de funcții de pe site.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              12.3. Politica privind cookie-urile: vă rugăm să consultați Politica
              privind cookie-urile pentru mai multe informații.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              13. Servicii de analiză
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              13.1. Google Analytics:
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              13.1.1. General: Acest site web utilizează Google Analytics, un serviciu
              de analiză web al Google Inc. („Google”). Google Analytics folosește
              cookie-uri. Informațiile despre utilizarea de către dvs. a site-ului web
              create de un cookie, vor fi de obicei transmise unui server Google din
              SUA și stocate acolo. Site-ul web folosește Google Analytics împreună cu
              opțiunea „_anonymizeIP()”. Aceasta înseamnă că adresa dumneavoastră IP
              va fi scurtată de Google în prealabil în statele membre ale UE sau în
              alte state membre ale Acordului privind Spațiul Economic European. Doar
              în cazuri excepționale, întreaga adresă IP va fi transmisă unui server
              Google din SUA și scurtată acolo. Prin urmare, orice legătură cu o
              anumită persoană este exclusă și datele personale sunt șterse imediat.
              Adresa IP transmisă de browserul dumneavoastră în cadrul utilizării
              Google Analytics nu va fi combinată cu alte date deținute de Google.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              13.1.2. Plug-in: Puteți împiedica colectarea de date prin intermediul
              cookie-ului cu privire la utilizarea de către dvs. a site-ului web
              (inclusiv adresa dumneavoastră IP), precum și prelucrarea de către
              Google a acestor date, prin descărcarea și instalarea următorului
              plug-in de browser: https://tools.google.com/dlpage/gaoptout?hl=ro.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              13.1.3. Scop: În numele operatorului site-ului web, Google va folosi
              aceste informații pentru a evalua utilizarea site-ului web, pentru a
              alcătui rapoarte despre activitățile site-ului web și pentru a oferi
              operatorului servicii suplimentare legate de utilizarea site-ului web și
              a internetului. Utilizăm Google Analytics pentru a analiza și a putea
              îmbunătăți în mod constant utilizarea site-ului nostru web. Prin
              statistici putem îmbunătăți serviciile noastre și le facem mai
              interesante pentru utilizatori. În acele cazuri speciale în care datele
              personale sunt transmise în SUA, Google este certificat prin scutul de
              confidențialitate UE-SUA. Baza prelucrării este articolul 6 alin. 1
              alin. f GDPR.
            </p>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              13.1.4. Informații despre furnizorul terță parte: Google Dublin, Google
              Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353
              (1) 436 1001; Termeni: https://www.google.com/analytics/terms/gb.html;
              prezentare generală privind protecția datelor:
              https://support.google.com/analytics/answer/6004245?hl=ro; și Politica
              de confidențialitate: https://www.google.de/intl/en/policies/privacy/.
            </p>
            <h2 className="font-semibold text-daintre text-xl lg:text-3xl mt-20 scroll-mt-24">
              14. Modificări ale acestei politici de confidențialitate
            </h2>
            <p className="mt-8 text-gray-900 first:mt-0 font-light text-lg lg:text-xl lg:leading-relaxed">
              Serviciile noastre sunt dinamice și introducem frecvent funcționalități
              noi, ceea ce poate necesita colectarea de informații noi. În cazul în
              care Compania decide să modifice această Politică de confidențialitate,
              va posta acele modificări direct în Servicii.
            </p>
          </div>
        </div>
      </div>
      );
};

export default TermeniPage;